import { memo, useEffect } from "react"
import { hasInvalidCubePayload } from "../../../../../Utils/UtilFunctions/cubeUtil"
import useTrackedCubeQuery from "../../../../../Utils/UtilFunctions/useTrackedCubeQuery"

const SensorStatus = (props: any) => {
	const cubeName: any = props.correspondingCubes.find((cube: any) => {
		return (
			cube.name.includes('LastValueAgg') && !cube.name.includes('Actionable')
		)
	})?.name

	//creating query to fetch connection status data
	const connectionStatusQuery: any = {
		measures: [`${cubeName}.lastValue`],
		filters: [
			{
				member: `${cubeName}.tagName`,
				operator: 'equals',
				values: props.uniqueTags.filter(
					(item: any, index: any) => props.uniqueTags.indexOf(item) === index
				)
			}
		],
		order: [[`${cubeName}.lastValue`, 'desc']],
		dimensions: [`${cubeName}.description`, `${cubeName}.deviceName`]
	}

	//executing cube query to fetch result
	const renderProps: any = useTrackedCubeQuery(connectionStatusQuery, {
		skip: hasInvalidCubePayload(connectionStatusQuery)
	})

	useEffect(() => {
		const thresholdValue = 128
		const data = renderProps?.resultSet?.loadResponse?.results[0]?.data[0]
		// two condition when the value != 128 and when the cube fails
		if (
			!renderProps?.isLoading &&
			renderProps?.resultSet !== null &&
			((data &&
				Number(data[connectionStatusQuery?.measures[0]]) !== thresholdValue) ||
				(!data && renderProps?.error !== null))
		) {
			props?.updateDisconnectedSensorsList()
		}
	}, [renderProps])

	return null
}

export default memo(SensorStatus)

export const env = {
	baseURL: process.env.REACT_APP_ARCH_API_BASE_URL as string,
	baseURL_monarch: process.env.REACT_APP_MONARCH_API_BASE_URL as string,
	baseURL_sat: process.env.REACT_APP_SAT_API_BASE_URL as string,
	workspaceId: process.env.REACT_APP_WORKSPACE_ID as string,
	organization_mode: process.env.REACT_APP_ORGANIZATION_MODE as string,
	environment: process.env.REACT_APP_ENVIRONMENT as string,
	REACT_APP_STORAGESASTOKEN: process.env.REACT_APP_STORAGES_AS_TOKEN as string,
	REACT_APP_STORAGERESOURCENAME: process.env
		.REACT_APP_STORAGE_RESOURCE_NAME as string,
	signalrConnectionUrl: process.env.REACT_APP_SIGNALR_CONNECTION_URL as string,
	CUBE_API_URL: process.env.REACT_APP_CUBE_API_URL as string,
	LogicApp_CustomEmail: process.env.REACT_APP_LOGIC_APP_CUSTOM_EMAIL as string,
	LogicApp_TicketNotification: process.env
		.REACT_APP_LOGIC_APP_TICKET_NOTIFICATION as string,
	LogicApp_CommentMention: process.env
		.REACT_APP_LOGIC_APP_COMMENT_MENTION as string,
	LogicApp_RoleNotification: process.env
		.REACT_APP_LOGIC_APP_ROLE_NOTIFICATION as string,
	LogicApp_UserAddDeleteNotification: process.env
		.REACT_APP_LOGIC_APP_USER_ADD_DELETE_NOTIFICATION as string,
	LogicApp_PreferenceModification: process.env
		.REACT_APP_LOGIC_APP_PREFERENCE_MODIFICATION as string,
	Azure_DevOps_Build_Number: process.env
		.REACT_APP_AZURE_DEVOPS_BUILDNUMBER as string,
	LogicApp_SATEmailTrigger: process.env
		.REACT_APP_LOGIC_APP_SAT_EMAIL_TRIGGER as string,
	monarch_app_url: process.env.REACT_APP_MONARCH_APP_URL as string,
	Injection_Machine_Assets_Share_Point_Url: process.env
		.REACT_APP_INJECTION_MACHINE_ASSETS_SHARE_POINT_URL as string,
	Injection_Mold_Assets_Share_Point_Url: process.env
		.REACT_APP_INJECTION_MOLD_ASSETS_SHARE_POINT_URL as string,
	eam_service_workorders_mgt: process.env
		.REACT_APP_eam_service_workorders_mgt as string,
	eam_service_assets_mgt: process.env.REACT_APP_eam_assets_mgt as string,
	eam_syncfusion_license: process.env
		.REACT_APP_eam_syncfusion_license as string,
	Annotations_Api: process.env.REACT_APP_ANNOTATIONS_API_URL as string,
	LGV_ONE_URL: process.env.REACT_APP_LGV_ONE_URL as string,
	QUALITY_CONTROL_TRENDS_WAREHOUSE_ID: process.env.REACT_APP_QUALITY_CONTROL_TRENDS_WAREHOUSE_ID as string,
}

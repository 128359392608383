import { clone, cloneDeep, get, set, isEmpty } from 'lodash'
import React, { useState, useEffect } from 'react'
import {
  Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField
} from '@mui/material'
import {
	ALPHANUMERIC_VALIDATION_REGEX,
	COLOR_CODE_REGEX
} from '../../Utils/SelfServiceHelper'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const CCPsMonitored = (props: any) => {
  const {
    items,
    onSeqChange,
    onError,
    resetListItems,
    currentUseCase,
    selectedPlant,
    tagsMasterList,
    assetTags,
  } = props;
	const [selectedStatus, selectStatus] = useState('')
	const [CCPsMonitoredError, setCCPsMonitoredError] = useState<any>({})
	const [StatusError, setStatusError] = useState<any>({})
  const [showNewField, setShowNewField] = useState<boolean>(false);
  const [newFieldValue, setNewFieldValue] = useState<any>({});
  const [newFieldError, setNewFieldError] = useState<any>({});
  const [isNewPlantToConfigure, setIsNewPlantToConfigure] = useState<boolean>(false);
  const [statusOptions, setStatusOptions] = useState<any>(null);
  const [currentPlantData, setCurrentPlantData]= useState<any>(items);
  const levelsList = useSelector((state: any) => state.levelsList);

  const dummyConfiguration = {
    CCPsMonitored: [],
    CIPStatus: [],
    ProdStatus: [],
    SterileStatus: [],
  };

  useEffect(() => {
    if (items === null || items?.length === 0) {
      const assetsData = levelsList.levels.filter((asset: any) => {
        return (
          asset.plant_id === selectedPlant?._id &&
          asset.devices.length !== 0 &&
          asset.use_case.includes(currentUseCase?._id)
        );
      });
      const result = assetsData.filter((ele: any) => {
        const deviceTag = ele.devices.filter((device: any) => {
          return device.usecase === undefined
            ? true
            : device.usecase === currentUseCase?._id;
        })[0]?.tag;

        const uniqueTags =
          ele?.asset_name === 'T40 Flex'
            ? [
                'T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS_CIPStatus_PV',
                'T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS_Recipe_PV_RecipeName',
                'T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS_Status_STAT',
              ]
            : tagsMasterList?.filter((tag: any) => {
                return (
                  tag.includes(`${deviceTag}_`) &&
                  assetTags?.some((assetTag: any) => tag.endsWith(assetTag))
                );
              });

        return uniqueTags.length > 1;
      });
      const data = result.map((obj: any) => {
        return {
          asset: obj['asset_alias'],
          ccp_count: 0,
        };
      });
      setIsNewPlantToConfigure(true);
      const updatedDummyConfiguration = {
        ...dummyConfiguration,
        CCPsMonitored: [...data],
      };
      setCurrentPlantData(updatedDummyConfiguration);
    } else {
      setCurrentPlantData(items);
    }
  }, [items]);

  useEffect(() => {
    let options: any;
    if (isNewPlantToConfigure) {
      options = dummyConfiguration ? Object.keys(dummyConfiguration) : [];
    } else {
      options = currentPlantData ? Object.keys(currentPlantData) : [];
    }
    setStatusOptions(options);
  }, [isNewPlantToConfigure, selectedStatus, currentPlantData]);

	const checkErrorUnderCurrentSelection = () => {
		let isErrorPresent = false
		if (selectedStatus === 'CCPsMonitored') {
			Object.keys(CCPsMonitoredError).forEach(key => {
				if (CCPsMonitoredError[key]) isErrorPresent = true
			})
		}
		if (selectedStatus === 'CIPStatus' || selectedStatus === 'ProdStatus') {
			Object.keys(StatusError).forEach(indexKey => {
				Object.keys(StatusError[indexKey]).forEach(key => {
					if (isEmpty(StatusError[indexKey][key])) isErrorPresent = true
				})
			})
		}
		return isErrorPresent
	}

	const handleItemValueChange = (
		data: any,
		editIndex: number,
		updatedKey = ''
	) => {
		const clonedUpdatedItems = cloneDeep(currentPlantData)

		const statusCondition =
			selectedStatus === 'CIPStatus' || selectedStatus === 'ProdStatus'  || selectedStatus === 'SterileStatus';
		const displayValueCondition =
			updatedKey === 'displayValue' &&
			(isEmpty(data[updatedKey]) ||
				!ALPHANUMERIC_VALIDATION_REGEX.test(data[updatedKey]))
		const displayColorCondition =
			updatedKey === 'displayColor' &&
			(isEmpty(data[updatedKey]) || !COLOR_CODE_REGEX.test(data[updatedKey]))
		const valueCondition = updatedKey === 'value' && isEmpty(data[updatedKey])

		if (
			selectedStatus === 'CCPsMonitored' &&
			(!data.ccp_count || data.ccp_count <= 0)
		) {
			const clonedError = clone(CCPsMonitoredError)
			setCCPsMonitoredError({
				...clonedError,
				[data.asset]: 'Invalid value'
			})
			onError(true)
		} else if (
			statusCondition &&
			(displayValueCondition || displayColorCondition || valueCondition)
		) {
			if (displayValueCondition) {
				const clonedError = clone(StatusError)
				setStatusError({
					...clonedError,
					[editIndex]: {
						...clonedError[editIndex],
						displayValue: 'Invalid value'
					}
				})
				onError(true)
			} else if (displayColorCondition) {
				const clonedError = clone(StatusError)
				setStatusError({
					...clonedError,
					[editIndex]: {
						...clonedError[editIndex],
						displayColor: 'Invalid value'
					}
				})
				onError(true)
			} else if (valueCondition) {
				const clonedError = clone(StatusError)
				setStatusError({
					...clonedError,
					[editIndex]: {
						...clonedError[editIndex],
						value: 'Invalid value'
					}
				})

				onError(true)
			}
		} else {
			set(
				clonedUpdatedItems,
				`${selectedStatus}`,
				get(clonedUpdatedItems, `${selectedStatus}`, []).map(
					(item: any, index: number) => {
						if (editIndex === index) {
							return data
						}
						return item
					}
				)
			)
			const clonedCCPError = clone(CCPsMonitoredError)

			setCCPsMonitoredError({
				...clonedCCPError,
				[data.asset]: ''
			})
			const clonedStatusError = clone(StatusError)
			delete clonedStatusError[editIndex]

			setStatusError({
				...clonedStatusError
			})

			const isErrorPresent = checkErrorUnderCurrentSelection()

			onSeqChange(clonedUpdatedItems, !isErrorPresent)
		}
	}

  const handleNewRecordBlur = (value: string, property: string) => {
    const updatedFieldData = {
      ...cloneDeep(newFieldValue),
      [property]: value,
    };
    const displayColorCondition = property === 'displayColor';
    const displayValueCondition = property === 'displayValue';
    const valueCondition = property === 'value';
    const newFieldErrorClone = clone(newFieldError);
    if (
      isEmpty(value) ||
      displayColorCondition ||
      displayValueCondition ||
      valueCondition
    ) {
      onError(true);
      set(newFieldErrorClone, `${property}`, 'Invalid value');
      setNewFieldError(newFieldErrorClone);
    }
    setNewFieldValue(updatedFieldData);
  };

  const handleNewFieldCheck = () => {
    const updatedFieldData = cloneDeep(newFieldValue);
    const updatedError = { key: '', tag: '' };

    const displayColorCondition =
      isEmpty(updatedFieldData.displayColor) ||
      newFieldValue.displayColor === JSON.stringify(0);

    const displayValueCondition =
      isEmpty(updatedFieldData.displayValue) ||
      newFieldValue.displayValue === JSON.stringify(0);

    const valueCondition =
      isEmpty(updatedFieldData.value) ||
      newFieldValue.value === JSON.stringify(0);

    if (displayValueCondition) {
      onError(true);
      set(updatedError, `displayValue`, 'Invalid value');
    }

    if (displayColorCondition) {
      onError(true);
      set(updatedError, `displayColor`, 'Invalid value');
    }

    if (valueCondition) {
      onError(true);
      set(updatedError, `value`, 'Invalid value');
    }

    if (displayColorCondition || displayValueCondition || valueCondition) {
      setNewFieldError(updatedError);
    } else if (!isEmpty(newFieldValue)) {
      const updatedData = cloneDeep(currentPlantData);
      onError(false);
      setNewFieldValue({});
      setNewFieldError({});
      if (
        selectedStatus !== '' &&
        newFieldValue !== '' &&
        newFieldValue !== null &&
        newFieldValue !== undefined
      ) {
        updatedData[selectedStatus].push(newFieldValue);
      }
      onSeqChange(updatedData);
      setShowNewField(false);
    }
  };


	return (
    (currentPlantData) ?
		<div className='selfConfigComponent' style={{ width: '470px' }}>
			<FormControl
				variant='outlined'
				fullWidth
				size='medium'
				sx={{ marginTop: '16px' }}
			>
				<InputLabel id='demo-multiple-name-label'>Select Status</InputLabel>
				<Select
					labelId='demo-multiple-name-label'
					id='demo-multiple-name'
					// multiple
					value={selectedStatus}
					onChange={(event: any) => {
						setCCPsMonitoredError({})
						setStatusError({})
						selectStatus(event.target.value)
						resetListItems()
					}}
					input={<OutlinedInput label='Select Status' />}
				>
					{statusOptions?.map((option: string) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{selectedStatus === 'CCPsMonitored'
				? get(currentPlantData, `${selectedStatus}`, [])?.map(
						(item: any, index: number) => (
							<React.Fragment key={`${item?.asset}`}>
								<TextField
									defaultValue={item.ccp_count}
									label={item.asset}
									type='number'
									fullWidth
									onChange={(event: any) => {
										const sendData = {
											...item,
											ccp_count: parseInt(event.target.value)
										}
										handleItemValueChange(sendData, index)
									}}
									sx={{
										marginTop: '16px'
									}}
									error={!!CCPsMonitoredError[item.asset]}
									helperText={CCPsMonitoredError[item.asset] || ''}
								/>
							</React.Fragment>
						)
					)
				: null}
      {selectedStatus === 'CIPStatus' ||
      selectedStatus === 'ProdStatus' ||
      selectedStatus === 'SterileStatus' ? (
        <>
          {get(currentPlantData, `${selectedStatus}`, [])?.map(
            (item: any, index: number) => (
              <React.Fragment key={`${item?.asset}`}>
                <Grid
                  container
                  style={{ margin: '0em 0em', padding: '0em' }}
                  spacing={2}
                  key={`${selectedStatus}`}
                >
                  <Grid style={{ padding: '8px 8px 0 0' }} item xs={4}>
                    <TextField
                      value={item.displayValue}
                      label='Display Value'
                      type='text'
                      fullWidth
                      onChange={(event: any) => {
                        const sendData = {
                          ...item,
                          displayValue: event.target.value
                        }
                        handleItemValueChange(sendData, index, 'displayValue');
                      }}
                      sx={{
                        marginTop: '16px',
                      }}
                      error={!!StatusError[index]?.displayValue}
                      helperText={StatusError[index]?.displayValue || ''}
                    />
                  </Grid>
                  <Grid style={{ padding: '8px 8px 0 0' }} item xs={4}>
                    <TextField
                      value={item.displayColor}
                      label='Display Color'
                      type='text'
                      fullWidth
                      onChange={(event: any) => {
                        const sendData = {
                          ...item,
                          displayColor: event.target.value
                        }
                        handleItemValueChange(sendData, index, 'displayColor');
                      }}
                      sx={{
                        marginTop: '16px',
                      }}
                      error={!!StatusError[index]?.displayColor}
                      helperText={StatusError[index]?.displayColor || ''}
                    />
                  </Grid>
                  <Grid style={{ padding: '8px 16px 0 0' }} item xs={4}>
                    <TextField
                      value={item.value}
                      label='Value'
                      type='text'
                      fullWidth
                      onChange={(event: any) => {
                        const sendData = {
                          ...item,
                          value: event.target.value
                        }
                        handleItemValueChange(sendData, index, 'value');
                      }}
                      sx={{
                        marginTop: '16px',
                      }}
                      error={!!StatusError[index]?.value}
                      helperText={StatusError[index]?.value || ''}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )
          )}
          {showNewField ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingTop: '8px',
              }}
            >
              <Grid style={{ padding: '8px 8px 0 0' }} item xs={4}>
                <TextField
                  label='Display Value'
                  type='text'
                  fullWidth
                  sx={{
                    marginTop: '16px',
                  }}
                  onBlur={(event) =>
                    handleNewRecordBlur(event.target.value, 'displayValue')
                  }
                />
              </Grid>
              <Grid style={{ padding: '8px 8px 0 0' }} item xs={4}>
                <TextField
                  label='Display Color'
                  type='text'
                  fullWidth
                  sx={{
                    marginTop: '16px',
                  }}
                  onBlur={(event) =>
                    handleNewRecordBlur(event.target.value, 'displayColor')
                  }
                />
              </Grid>
              <Grid style={{ padding: '8px 16px 0 0' }} item xs={4}>
                <TextField
                  label='Value'
                  type='text'
                  fullWidth
                  sx={{
                    marginTop: '16px',
                  }}
                  onBlur={(event) =>
                    handleNewRecordBlur(event.target.value, 'value')
                  }
                />
              </Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <CheckIcon onClick={() => handleNewFieldCheck()} />
                <CloseIcon
                  onClick={() => {
                    setNewFieldValue({});
                    setShowNewField(false);
                    setNewFieldError({});
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Button
                variant='contained'
                onClick={() => {
                  setShowNewField(true);
                  setNewFieldError({});
                }}
              >
                Add New
              </Button>
            </div>
          )}
        </>
      ) : null}
    </div>
    : (
    <div
      style={{
        padding: '25px 5px 10px',
        height: '24px',
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
      }}
    >
      Data not found
    </div>
  ));
};

export default CCPsMonitored

import { CubeContext, useCubeMeta } from '@cubejs-client/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography
} from '@mui/material'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../../../App'
import { ReactComponent as DocumentationIcon } from '../../../../Icons/Documentation.svg'
import { isUserAllowed } from '../../../../Utils/SelfServiceHelper'
import { DYNAMICQUERY } from '../../../../Utils/UtilFunctions/constant'
import {
	getSeedValues,
	sortAssetOrder,
	sortParentOrder
} from '../../../../Utils/UtilFunctions/useCaseUtilFunctions'
import ConfigureDashboardAssets from '../../../../componets/ConfigureDashboardAssets'
import { Asset, Asset_Usecase } from '../../../../models/Types_Interfaces'
import UtilitiesAlertHistory from './AlertHistory'
import AssetStatusIndicator from './AssetIndicator'
import { hasInvalidCubePayload } from "../../../../Utils/UtilFunctions/cubeUtil"
import fetchAndTrackCubeQuery from '../../../../Utils/UtilFunctions/trackedCubeQuery'
export const LandingPageContext: any = React.createContext(null)

function UtilitiesLandingPage() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const metaCubeNames = useCubeMeta()
	const { cubejsApi } = useContext(CubeContext)
	const appContextProps: any = useContext(AppContext)
	const seedsList = useSelector((state: any) => state.seedsList)
	const usecaseList = useSelector((state: any) => state.usecaseList)
	const levelsList = useSelector((state: any) => state.levelsList)
	const plantsList = useSelector((state: any) => state.plantsList)
	const usersList = useSelector((state: any) => state.usersList)
	const assetTypesList = useSelector((state: any) => state.assetTypesList)
	const [correspondingCubes, setCorrespondingCubes] = useState<any>([])
	const [tagsCube, setTagsCube] = useState<any>(null)
	const [isLoadingPlant, setIsPlantLoading] = useState<any>(false)
	const [openConfigureModal, setConfigureModal] = React.useState(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isError, setIsError] = useState<boolean>(false)
	const [allParentAsset, setAllParentAsset] = useState<any>([])
	const [selectedParentAsset, setSelectedParentAsset] = useState<any>(null)
	const [selectedPlant, setSelectedPlant] = useState<any>(null)
	const [selectedAssetType, setSelectedAssetType] = useState<any>(null)
	const [expanded, setExpanded] = useState<string | false>(false)
	const [tagsArray, setTagsArray] = useState<any>([])
	const [currentUsecase, setCurrentUsecase] = React.useState<any>([])
	const [availablePlants, setAvailablePlants] = React.useState<any>([]) //fetching and storing current usecase details through url
	const [filterMessage, setFilterMessage] = React.useState<any>({
		useCaseId: '',
		plantId: ''
	})
	// Using current plant code if available or defaultCode
	const defaultPlantCode = appContextProps.selectedPlantForCube || 'HOU'

	//getting usecase from url
	const usecaseRoute = window.location.href.substring(
		window.location.href.lastIndexOf('/') + 1,
		window.location.href.length
	)

	// use effect to find and sotre use case details using url
	React.useEffect(() => {
		const tempUsecase = usecaseList.usecases.find((usecase: Asset_Usecase) => {
			return usecase.routes_use_code === usecaseRoute
		})
		setCurrentUsecase(tempUsecase)
		setFilterMessage({ ...filterMessage, useCaseId: tempUsecase?._id })
		const groupArrayObject = levelsList.levels
			.filter((asset: any) => {
				return asset.use_case.includes(tempUsecase._id)
			})
			.reduce((group: any, arr: any) => {
				const { plant_id } = arr
				group[plant_id] = group[plant_id] ?? []
				group[plant_id].push(arr)
				return group
			}, {})

		setAvailablePlants(
			Object.keys(groupArrayObject).map((ele: any) => {
				return plantsList.plants.find((plant: any) => {
					return plant._id === ele
				})
			})
		)
	}, [usecaseList.usecases, levelsList.levels])

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false)
		}

	// Orders
	// fetching seed for areas
	const orderArray = getSeedValues(
		seedsList,
		'AreasOrderArray',
		'Utilities CBM',
		defaultPlantCode
	)

	// fetching seed for assets
	const orderArrayForAssets = getSeedValues(
		seedsList,
		'AssetsOrderArray',
		'Utilities CBM',
		defaultPlantCode
	)

	const dynamicQuery: any =
		getSeedValues(seedsList, DYNAMICQUERY, 'Utilities CBM', defaultPlantCode)
			.length === 0
			? getSeedValues(seedsList, DYNAMICQUERY, 'Utilities CBM', 'HOU')
			: getSeedValues(
					seedsList,
					DYNAMICQUERY,
					'Utilities CBM',
					defaultPlantCode
				)

	//Helper methods
	const findLevel0Asset = (plantId: string, useCaseName: string) => {
		return levelsList.levels
			.filter((asset: Asset) => {
				return (
					asset.plant_id === plantId &&
					(asset.asset_category === 'Line' ||
						asset.asset_category === 'Asset Area') &&
					asset.use_case.includes(
						usecaseList.usecases.find((usecase: Asset_Usecase) => {
							return usecase.level_use_case === useCaseName
						})?._id
					)
				)
			})
			?.sort((a: any, b: any) => sortParentOrder(a, b, orderArray))
	}

	const findParentAsset = (
		plantId: string,
		useCaseName: string,
		assetId: string,
		selectedAssetType: any
	) => {
		return levelsList.levels.filter((asset: any) => {
			return (
				asset.plant_id === plantId &&
				asset.parent_asset === assetId &&
				asset.use_case.includes(
					usecaseList.usecases.find((usecase: Asset_Usecase) => {
						return usecase.level_use_case === useCaseName
					})._id
				) &&
				(selectedAssetType !== null
					? asset.asset_type === selectedAssetType._id
					: true)
			)
		})
	}

	const getTags = async () => {
		const tagsQuery: any = {
			dimensions: [`${tagsCube.name}.tagName`, `${tagsCube.name}.deviceName`],
			filters: [
				{
					member: `${tagsCube.name}.tagName`,
					operator: 'contains',
					values: dynamicQuery?.masterTag
				}
			]
		}

		setIsLoading(true)
		if (!hasInvalidCubePayload(tagsQuery)) {
			const {isSuccess, isError, data: fetchedTagsResult} = await fetchAndTrackCubeQuery(cubejsApi, tagsQuery);
			if(isSuccess){
				setTagsArray(
					fetchedTagsResult
						?.map((dataArray: any) => {
							return dataArray.map((datapoint: any) => {
								const key: any = Object.keys(datapoint).find((key: any) => {
									return key.includes('tagName')
								})
								return datapoint[key]
							})
						})
						.flat()
				)
			}else if(isError){
				setIsError(true)
				setIsLoading(false)
			}
		} else {
			setIsLoading(false)
			dispatch({
				type: 'LOAD_SNACKBAR',
				payload: {
					isShow: true,
					message: 'Cube query has invalid payload',
					type: 'error',
					open: true
				}
			})
		}

		setIsLoading(false)
	}

	//Selecting Accessible Plants
	React.useEffect(() => {
		if (plantsList.plants.length !== 0) {
			if (
				usersList.accessiblePlants.filter((ele: any) => {
					return ele?.plant_code === defaultPlantCode
				}).length > 0 &&
				availablePlants.filter((ele: any) => {
					return ele?.plant_code === defaultPlantCode
				}).length > 0
			) {
				setSelectedPlant(
					plantsList.plants.find((plant: any) => {
						return plant.plant_code === defaultPlantCode
					})
				)
				setIsPlantLoading(true)
			} else {
				if (
					usersList.accessiblePlants &&
					availablePlants.length > 0 &&
					usersList.accessiblePlants.filter((accessibePlant: any) => {
						return availablePlants
							.map((plant: any) => {
								return plant?._id
							})
							.includes(accessibePlant._id)
					}).length > 0
				) {
					setSelectedPlant(
						usersList.accessiblePlants.filter((accessibePlant: any) => {
							return availablePlants
								.map((plant: any) => {
									return plant?._id
								})
								.includes(accessibePlant._id)
						})[0]
					)
					//setting plant code for cube fetching of access token
					setIsPlantLoading(true)
					appContextProps.setSelectedPlantForCube(
						usersList.accessiblePlants.filter((accessibePlant: any) => {
							return availablePlants
								.map((plant: any) => {
									return plant?._id
								})
								.includes(accessibePlant._id)
						})[0]?.plant_code
					)
				}
			}
		}
	}, [plantsList.plants, availablePlants])

	React.useEffect(() => {
		if (selectedPlant !== undefined && selectedPlant !== null) {
			setFilterMessage({ ...filterMessage, plantId: selectedPlant._id })
		}
	}, [selectedPlant])

	// Fetching Cube Datas
	useEffect(() => {
		if (
			metaCubeNames &&
			metaCubeNames.response &&
			metaCubeNames.response.cubes.length > 0 &&
			selectedPlant !== null &&
			levelsList.levels.length !== 0 &&
			usecaseList.usecases.length !== 0
		) {
			setCorrespondingCubes(
				metaCubeNames.response.cubes.filter((cubeName: any) => {
					return cubeName.name.includes(selectedPlant?.plant_code.toUpperCase())
				})
			)

			setTagsCube(
				metaCubeNames.response.cubes.find((cube: any) => {
					return cube.name === 'Tags'
				})
			)

			setIsPlantLoading(false)
			const parentAsset = findLevel0Asset(
				selectedPlant?._id,
				currentUsecase?.level_use_case
			)
			setAllParentAsset(parentAsset)
			if (selectedParentAsset === null) {
				setSelectedParentAsset(parentAsset[0])
			}
		} else {
			setIsPlantLoading(false)
		}
	}, [
		selectedPlant,
		metaCubeNames.response,
		usecaseList.usecases,
		levelsList.levels,
		seedsList
	])

	// Fetching Tags
	React.useEffect(() => {
		if (selectedPlant !== null) {
			if (correspondingCubes?.length > 0 && tagsArray.length === 0) {
				getTags()
			}
		}
	}, [correspondingCubes])

	React.useEffect(() => {
		if (location.state !== null) {
			if (location.state.selectedParentAsset) {
				setSelectedParentAsset(location.state.selectedParentAsset)
			}

			if (location.state.expanded) {
				setExpanded(location.state.expanded)
			}
		}
	}, [location])

	// Make network call ti filter messages
	React.useEffect(() => {
		if (filterMessage?.useCaseId !== '' && filterMessage?.plantId !== '') {
			dispatch({
				type: 'LOAD_MESSAGES',
				payload: {
					plantId: filterMessage?.plantId,
					useCaseId: filterMessage?.useCaseId
				}
			})

			const data = {
				pageSize: 750,
				page: 1,
				category: 'all_tickets',
				ticketId: '',
				useCaseId: filterMessage.useCaseId,
				plantId: filterMessage.plantId,
				asset_area: '',
				status: '',
				assignee: '',
				asset_alias: '',
				usecase_name: ''
			}
			dispatch({ type: 'LOAD_ALL_TICKETS', payload: data })

			// dispatch({ type: "LOAD_ALL_TICKETS" });
		}
	}, [filterMessage])

	return (
		<>
			{levelsList.loading ? (
				<CircularProgress style={{ margin: '20% 45%' }} />
			) : (
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='left'
					spacing={2}
					style={{
						padding: '20px'
					}}
				>
					{/* Plant Selection */}
					<Grid item xs={12} sm={4} md={2}>
						<Autocomplete
							options={usersList.accessiblePlants.filter(
								(accessibePlant: any) => {
									return availablePlants
										.map((plant: any) => {
											return plant?._id
										})
										.includes(accessibePlant._id)
								}
							)}
							getOptionLabel={(option: any) => option.plant_name}
							value={selectedPlant}
							onChange={(event: any, newValue: any) => {
								setSelectedPlant(newValue)
								appContextProps.setSelectedPlantForCube(newValue.plant_code)
								setSelectedParentAsset(null)
								setIsError(false)
								setIsPlantLoading(true)
								setTagsArray([])
							}}
							renderInput={(params: any) => (
								<TextField {...params} placeholder='Select Plant' />
							)}
							disableClearable
							sx={{ width: '185px' }}
						/>
					</Grid>
					<Grid item sm={8} md={10}></Grid>

					{(selectedPlant === null && !isLoading) || isLoadingPlant === true ? (
						<CircularProgress style={{ margin: '20% 45%' }} />
					) : (
						<Fragment>
							{/* Usecase Header */}
							<Grid item xs={12} md={3}></Grid>
							<Grid item xs={12} md={5}>
								<Typography
									style={{
										textAlign: 'center',
										fontWeight: '500',
										fontSize: '16px'
									}}
									align='left'
								>
									{selectedPlant?.plant_name}
								</Typography>
								<Typography
									style={{
										textAlign: 'center',
										fontWeight: '700',
										fontSize: '24px'
									}}
									align='left'
								>
									Utilities CBM/PdM
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								md={2}
								display='flex'
								sx={{ justifyContent: { xs: 'center', sm: 'right' } }}
							>
								{isUserAllowed(usersList.currentUserPermissions) ? (
									<Button
										variant='contained'
										style={{
											height: '4.2em',
											fontWeight: 400,
											marginRight: '7%',
											minWidth: '100px'
										}}
										onClick={() => {
											setConfigureModal(true)
										}}
									>
										Configure
									</Button>
								) : null}
								{selectedPlant?.plant_code && (
									<a
										href={`https://niagarawater1.sharepoint.com/sites/mfg/SitePages/${selectedPlant?.plant_code.toUpperCase()}-Plant.aspx`}
										target='_blank'
										rel='noopener noreferrer'
									>
										<Button
											variant='contained'
											style={{
												height: '4.2em',
												fontWeight: 400
											}}
											startIcon={<DocumentationIcon />}
										>
											Documentation
										</Button>
									</a>
								)}
							</Grid>
							<Grid item xs={12} md={2}>
								<Autocomplete
									options={usersList.currentUser.bookmarked_reports
										?.filter(
											(data: any) => data.usecase_id === currentUsecase?._id
										)
										?.map((assetData: any) => assetData.assets)
										.flat()
										?.filter((item: any) => {
											return (
												levelsList.levels.find((asset: any) => {
													return (
														asset._id === item &&
														asset.use_case.includes(
															usecaseList.usecases.find(
																(usecase: Asset_Usecase) => {
																	return (
																		usecase.level_use_case ===
																		currentUsecase?.level_use_case
																	)
																}
															)?._id
														)
													)
												})?.plant_id === selectedPlant?._id
											)
										})
										.map((item: any) => {
											return levelsList.levels.find((asset: any) => {
												return asset._id === item
											})
										})}
									getOptionLabel={(option: any) => option?.asset_name}
									// value={selectedAssetType}
									onChange={(event: any, newValue: any) => {
										// setSelectedAssetType(newValue);
										navigate(`/usecase/utilities/${newValue._id}`)
									}}
									renderInput={(params: any) => (
										<TextField {...params} placeholder='Bookmarks' />
									)}
								/>
							</Grid>

							{isLoading ? (
								<Grid container item>
									<Grid
										item
										style={{
											padding: '20px'
										}}
										xs={10}
										justifyContent={'center'}
										alignItems={'center'}
										display={'flex'}
									>
										<CircularProgress style={{ margin: '12% 45%' }} />
									</Grid>
								</Grid>
							) : isError ? (
								<Grid container item>
									<Grid
										item
										style={{
											padding: '20px'
										}}
										xs={10}
										justifyContent={'center'}
										alignItems={'center'}
										display={'flex'}
									>
										<Typography
											style={{
												margin: '35px auto',
												fontSize: '22px'
											}}
										>
											There was an error encountered while fetching the data.
										</Typography>
									</Grid>
								</Grid>
							) : usersList.accessiblePlants.filter((accessibePlant: any) => {
									return availablePlants
										.map((plant: any) => {
											return plant?._id
										})
										.includes(accessibePlant._id)
							  }).length === 0 ? (
								<Typography
									style={{
										margin: '50px 15% 0px 0%',
										fontSize: '22px'
									}}
								>
									No accessible plant found for the selected usecase under
									currently assigned role.
								</Typography>
							) : tagsArray.length === 0 ? (
								<Grid container item>
									<Grid
										item
										style={{
											padding: '20px'
										}}
										xs={10}
										justifyContent={'center'}
										alignItems={'center'}
										display={'flex'}
									>
										<Typography
											style={{
												margin: '35px auto',
												fontSize: '22px'
											}}
										>
											No data found
										</Typography>
									</Grid>
								</Grid>
							) : allParentAsset.length === 0 ? (
								<Grid container item>
									<Grid
										item
										style={{
											padding: '20px'
										}}
										xs={10}
										justifyContent={'center'}
										alignItems={'center'}
										display={'flex'}
									>
										<Typography
											style={{ margin: '35px auto', fontSize: '22px' }}
										>
											No assets belonging to the &apos;Utilities&apos; usecase
											found for the selected plant.
										</Typography>
									</Grid>
								</Grid>
							) : (
								<Fragment>
									{/* Parent and Asset Type Selection */}

									<Grid item xs={12} md={2}>
										<Grid item xs={12}>
											<Autocomplete
												options={assetTypesList.assetTypes.sort(
													(a: any, b: any) =>
														a.asset_type_name.toLowerCase() >
														b.asset_type_name.toLowerCase()
															? 1
															: b.asset_type_name.toLowerCase() >
																  a.asset_type_name.toLowerCase()
																? -1
																: 0
												)}
												getOptionLabel={(option: any) => option.asset_type_name}
												value={selectedAssetType}
												onChange={(event: any, newValue: any) => {
													setSelectedAssetType(newValue)
												}}
												renderInput={(params: any) => (
													<TextField {...params} placeholder='Asset Type' />
												)}
											/>
										</Grid>
									</Grid>

									<Grid
										xs={12}
										md={10}
										spacing={1}
										sx={{
											justifyContent: { xs: 'left', md: 'right' },
											marginTop: { sm: '1%', md: '0%' }
										}}
										alignItems={'center'}
										display={'flex'}
									>
										{allParentAsset.map((ele: any) => {
											return (
												<Typography
													style={{
														textAlign: 'center',
														fontWeight: '400',
														fontSize: '14px',
														border:
															selectedParentAsset?._id === ele._id
																? '1px solid #1DAC56'
																: '1px solid #FFFFFF',
														color:
															selectedParentAsset?._id === ele._id
																? '#1DAC56'
																: '#FFFFFF',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														padding: '1em',
														width: 'fit-content',
														borderRadius: '8px',
														cursor: 'pointer',
														margin: '10px'
													}}
													onClick={() => {
														setSelectedParentAsset(ele)
													}}
													// align="left"
												>
													{ele.asset_name.substring(
														ele.asset_name.indexOf(' '),
														ele.asset_name.length
													)}
												</Typography>
											)
										})}
									</Grid>
									{/* Temporarily disable the code for the health level Legend */}
									{/* <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    // alignItems="center"
                    justifyContent={"center"}
                    display={"flex"}
                    sx={{ padding: "10px 0px 0px 20px" }}
                  >
                    <Grid
                      container
                      xs={12}
                      style={{
                        margin: "5px 5px 0px 8px",
                        border: "0.5px solid #8476EF",
                        padding: "10px",
                        display: "flex",
                        borderRadius: "8px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          borderRadius: "8px",
                          margin: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // padding: "10px",
                        }}
                      >
                        <InfoIcon
                          style={{ margin: "0px 5px", color: "#8476EF" }}
                          // style={{
                          //   display: "inline",
                          //   transform: expand === true ? "rotate(180deg)" : "",
                          //   color: "#fff",
                          //   fontSize: "30px",

                          //   // backgroundColor: appMode === "Dark" ? "#8476EF" : "#1245ab",
                          // }}
                          // onClick={() => {
                          //   setExpand(!expand);
                          // }}
                        />
                        Health Level Legend
                        <ExpandMoreIcon
                          style={{
                            display: "inline",
                            transform: expand === true ? "rotate(180deg)" : "",
                            color: "#fff",
                            fontSize: "30px",

                            // backgroundColor: appMode === "Dark" ? "#8476EF" : "#1245ab",
                          }}
                          onClick={() => {
                            setExpand(!expand);
                          }}
                        />
                      </Grid>
                      {expand === true ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={3.75}
                            style={{
                              border: "0.3px solid #D9D9D9",
                              borderRadius: "8px",
                              padding: "10px",
                              margin: "5px 2px",
                              // padding: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                display: "inline",
                                // width: "fit-content",
                                color: "#99CC33",
                              }}
                            >
                              Running
                            </Typography>
                            <Typography
                              style={{
                                display: "inline",
                                // width: "fit-content",
                              }}
                            >
                              {" "}
                              - Motor is running
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3.75}
                            style={{
                              border: "0.3px solid #D9D9D9",
                              borderRadius: "8px",
                              padding: "10px",
                              margin: "5px 2px",
                              // padding: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                display: "inline",
                                color: "#1675e0",

                                // width: "fit-content",
                              }}
                            >
                              No Status
                            </Typography>
                            <Typography
                              style={{
                                display: "inline",
                                // width: "fit-content",
                              }}
                            >
                              {" "}
                              - Motor status is not received
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3.75}
                            style={{
                              border: "0.3px solid #D9D9D9",
                              borderRadius: "8px",
                              padding: "10px",
                              margin: "5px 2px",
                              // padding: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                display: "inline",
                                color: "#CC3300",

                                // width: "fit-content",
                              }}
                            >
                              Not Running
                            </Typography>
                            <Typography
                              style={{
                                display: "inline",
                                // width: "fit-content",
                              }}
                            >
                              {" "}
                              - Motor is not running
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid> */}

									{/* Main Page */}
									<Grid
										item
										container
										direction='row'
										justifyContent='start'
										alignItems='start'
										spacing={2}
										style={{
											padding: '18px'
										}}
										xs={12}
										mt={2}
									>
										{/* Accordion */}

										<Grid container item xs={10}>
											{findParentAsset(
												selectedPlant?._id,
												currentUsecase?.level_use_case,
												selectedParentAsset?._id,
												selectedAssetType
											)
												.sort((a: any, b: any) =>
													sortAssetOrder(a, b, orderArrayForAssets)
												)
												.map((ele: any) => {
													return (
														<Grid item xs={12} mb={2}>
															<Accordion
																expanded={expanded === ele._id}
																onChange={handleChange(ele._id)}
																style={{
																	backgroundColor: '#293368',
																	border: '1px solid #139BFE',
																	boxShadow:
																		'0px 0px 20px rgba(19, 169, 254, 0.25)',
																	borderRadius: '12px'
																	//   padding: "8px",
																}}
															>
																<AccordionSummary
																	expandIcon={
																		<ExpandMoreIcon
																			style={{ color: 'white' }}
																		/>
																	}
																	aria-controls='panel1bh-content'
																	id='panel1bh-header'
																>
																	<Typography
																		sx={{
																			color: '#E6E1E5',
																			fontSize: '16px',
																			lineHeight: '36px',
																			fontWeight: '700'
																		}}
																	>
																		{ele.asset_alias || ele.asset_name}
																	</Typography>
																</AccordionSummary>
																<AccordionDetails
																	sx={{ padding: '5px 20px 20px 20px' }}
																>
																	<Grid container spacing={2}>
																		{/* Top */}
																		{expanded === ele._id && findParentAsset(
																			selectedPlant?._id,
																			currentUsecase?.level_use_case,
																			ele?._id,
																			selectedAssetType
																		)
																			.sort((a: any, b: any) => {
																				return a.asset_alias
																					?.toLowerCase()
																					.localeCompare(
																						b.asset_alias?.toLowerCase(),
																						undefined,
																						{
																							numeric: true,
																							sensitivity: 'base'
																						}
																					)
																			})
																			.map((assetComponent: any) => {
																				return (
																					<LandingPageContext.Provider
																						value={{
																							correspondingCubes:
																								correspondingCubes,
																							tagsArray: tagsArray,
																							defaultPlantCode: defaultPlantCode
																						}}
																					>
																						<Grid
																							item
																							xs={12}
																							sm={6}
																							lg={4}
																							style={{
																								cursor: 'pointer'
																							}}
																							onClick={() => {
																								navigate(
																									`/usecase/utilities/${assetComponent._id}`,
																									{
																										state: {
																											expanded: expanded,
																											selectedParentAsset:
																												selectedParentAsset
																										}
																									}
																								)
																							}}
																						>
																							<AssetStatusIndicator
																								asset={assetComponent}
																								parentAsset={ele}
																							/>
																						</Grid>
																					</LandingPageContext.Provider>
																				)
																			})}
																	</Grid>
																</AccordionDetails>
															</Accordion>
														</Grid>
													)
												})}
										</Grid>

										{/* Alert history */}
										<Grid item xs={2}>
											<UtilitiesAlertHistory
												filterMessage={filterMessage}
												selectedPlant={selectedPlant}
											/>
										</Grid>
									</Grid>
								</Fragment>
							)}
						</Fragment>
					)}
				</Grid>
			)}
			{isUserAllowed(usersList.currentUserPermissions) ? (
				<ConfigureDashboardAssets
					open={openConfigureModal}
					handleClose={() => setConfigureModal(false)}
					currentUseCase='Utilities CBM'
					selectedPlant={selectedPlant}
					selectedParentAsset={selectedParentAsset}
					selectedAssetType={selectedAssetType}
				/>
			) : null}
		</>
	)
}

export default UtilitiesLandingPage

import {
	Grid,
	Typography,
	Divider,
	Button,
	CircularProgress
} from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useCubeMeta } from '@cubejs-client/react'
import { CubeContext } from '@cubejs-client/react'
import { hasInvalidCubePayload } from '../../../../../Utils/UtilFunctions/cubeUtil'
import { ExcelService } from '../../../../../Utils/ExportToExcelTicketDetails'
import { loadSnackbar } from '../../../../../Utils/util'
import DisconnectedSensorsTable from './DisconnectedSensorsTable'
import DisconnectedSensorsPage from './DisconnectedSensorsPage'
import ErrorBoundary from "./Error"
import fetchAndTrackCubeQuery from '../../../../../Utils/UtilFunctions/trackedCubeQuery'

const DisconnectedSensors = (props: any) => {
	const levelsList = useSelector((state: any) => state.levelsList)
	const plantsList = useSelector((state: any) => state.plantsList)
	const usecaseList = useSelector((state: any) => state.usecaseList)
	const sensorsList = useSelector((state: any) => state.sensorsList)
	const [currentPlant, setCurrentPlant] = useState<any>(null)
	const [currentUsecase, setCurrentUsecase] = useState<any>(null)
	const [connectionStatusTags, setConnectionStatusTags] = useState<any>(null)
	const [tagsCube, setTagsCube] = useState<any>(null)
	const [currentPlantAssets, setCurrentPlantAssets] = useState<any>(null)
	const [mpDevicesLinkedAssets, setMPDevicesLinkedAssets] = useState<any>(null)
	const [correspondingCubes, setCorrespondingCubes] = useState<any>(null)
	const [disConnectedSensorsList, setDisConnectedSensorsList] = useState<any>(
		[]
	)
	const metaCubeNames = useCubeMeta()
	const { cubejsApi } = useContext(CubeContext)
	const dispatch = useDispatch()
	const { plantId } = useParams()
	const navigate = useNavigate()

	const getAssets = async () => {
		const plantAssets = levelsList.levels.filter((ele: any) => {
			return ele.plant_id === currentPlant?._id
		})
		setCurrentPlantAssets(plantAssets)

		const mpAssets = plantAssets.filter((ele: any) => {
			return (
				ele.plant_id === currentPlant?._id &&
				ele.use_case.includes(currentUsecase?._id) &&
				ele.devices.length > 0 &&
				ele.devices.some((item: any) => item.usecase === currentUsecase?._id)
			)
		})
		setMPDevicesLinkedAssets(mpAssets)
	}

	const getHealthLevelTags = async () => {
		if (tagsCube !== undefined && tagsCube !== null) {
			const connectionTagsQuery: any = {
				dimensions: [`${tagsCube.name}.tagName`, `${tagsCube.name}.deviceName`],
				filters: [
					{
						member: `${tagsCube.name}.tagName`,
						operator: 'contains',
						values: ['_ConnectionStatusOfRadio']
					}
				]
			}

			if (!hasInvalidCubePayload(connectionTagsQuery)) {
				const {isSuccess, isError, data: fetchedConnectionTags} = await fetchAndTrackCubeQuery(cubejsApi, connectionTagsQuery);
				if(isSuccess){
					setConnectionStatusTags(
						fetchedConnectionTags
							?.map((dataArray: any) => {
								return dataArray.map((datapoint: any) => {
									const key: any = Object.keys(datapoint).find((key: any) => {
										return key.includes('tagName')
									})
	
									return datapoint[key]
								})
							})
							.flat()
					)
				}else if(isError){
					dispatch({
						type: 'LOAD_SNACKBAR',
						payload: {
							isShow: true,
							message: 'Cube call failed',
							type: 'error',
							open: true
						}
					})		
				}
			} else {
				dispatch({
					type: 'LOAD_SNACKBAR',
					payload: {
						isShow: true,
						message: 'Cube query has invalid payload',
						type: 'error',
						open: true
					}
				})
			}
		}
	}

	// function to convert data in excel format
	const exportCSV = async () => {
		const excelService = new ExcelService()
		const date = new Date()

		let formattedDate = `${date
			.toLocaleString('en-US', {
				year: '2-digit',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				hour12: false,
				timeZoneName: 'short'
			})
			.replace(/\//g, '')
			.replace(/,/g, '_')
			.replace(/ /g, '')
			.replace(/:/g, '')}`

		// Check if formattedDate contains either '+' or '-'
		const plusIndex = formattedDate.indexOf('+')
		const minusIndex = formattedDate.indexOf('-')

		// Find the index of the first occurrence of either '+' or '-'
		const cutIndex =
			plusIndex !== -1
				? plusIndex
				: minusIndex !== -1
					? minusIndex
					: formattedDate.length

		// Slice the formattedDate up to the found index
		formattedDate = formattedDate.slice(0, cutIndex)

		const data: any = [[...disConnectedSensorsList]]
		await excelService
			.exportAsExcelFile(
				data,
				`NB ARCH- DisconnectedSensors- ${currentPlant.plant_code}- ${formattedDate}`,
				[`Disconnected Sensors- ${currentPlant.plant_code}`]
			)
			.catch(() => dispatch(loadSnackbar()))
	}

	useEffect(() => {
		const plant = plantsList?.plants?.find(
			(plant: any) => plant._id === plantId
		)
		setCurrentPlant(plant)

		const usecase = usecaseList.usecases.find((usecase: any) => {
			return usecase.routes_use_code === 'motors-and-pumps'
		})
		setCurrentUsecase(usecase)
	}, [])

	useEffect(() => {
		getHealthLevelTags()
	}, [correspondingCubes])

	useEffect(() => {
		getAssets()
	}, [connectionStatusTags])

	useEffect(() => {
		if (
			metaCubeNames &&
			metaCubeNames.response &&
			metaCubeNames.response.cubes.length > 0
		) {
			setTagsCube(
				metaCubeNames.response.cubes.find((cube: any) => {
					return cube.name === 'Tags'
				})
			)

			setCorrespondingCubes(
				metaCubeNames.response.cubes.filter((cubeName: any) => {
					return cubeName.name.includes(currentPlant?.plant_code.toUpperCase())
				})
			)
		}
	}, [currentPlant, metaCubeNames.response])

	return (
		<ErrorBoundary>
			<Helmet>
				<title style={{ color: 'yellow' }}>Disconnected Sensors</title>
			</Helmet>
			<Grid
				container
				justifyContent='space-between'
				alignItems='center'
				spacing={2}
				style={{
					padding: '5px 30px'
				}}
				sx={theme => ({
					fill: theme.palette.common.white,
					stroke: theme.palette.divider,
					strokeWidth: 1
				})}
			>
				<Grid item xs={12}></Grid>
				<Grid
					item
					onClick={() =>
						navigate(`/usecase/motors-and-pumps`, {
							state: {
								currentViewPlant: currentPlant
							}
						})
					}
				>
					<ArrowBackIcon />
				</Grid>

				<Grid item xs={7}>
					<Typography
						style={{
							textAlign: 'center',
							fontWeight: '500',
							fontSize: '16px'
						}}
						align='left'
					>
						{currentPlant?.plant_name}
					</Typography>
					<Typography
						style={{
							textAlign: 'center',
							fontWeight: '700',
							fontSize: '24px'
						}}
						align='right'
					>
						Disconnected Sensors
					</Typography>
				</Grid>

				<Button
					variant='contained'
					endIcon={<FileDownloadIcon />}
					onClick={exportCSV}
					disabled={disConnectedSensorsList.length === 0}
				>
					Export
				</Button>
			</Grid>
			<Divider
				style={{
					margin: '5px 28px',
					width: '-webkit-fill-available',
					height: '2px',
					backgroundColor: '#8476EF',
					opacity: 0.4
				}}
			/>
			{mpDevicesLinkedAssets &&
				mpDevicesLinkedAssets?.map((asset: any) => {
					return asset.devices
						.sort((a: any, b: any) => {
							return sensorsList.sensors.find((sensor: any) => {
								return sensor._id === a.sensor_id
							})?.sensor_name <
								sensorsList.sensors.find((sensor: any) => {
									return sensor._id === b.sensor_id
								})?.sensor_name
								? 1
								: -1
						})
						.map((device: any) => {
							return (
								<DisconnectedSensorsPage
									key={device._id}
									sensor={sensorsList.sensors.find((sensor: any) => {
										return sensor._id === device.sensor_id
									})}
									tag={device.tag}
									// parentAsset={levelsList.levels.find((ele: any) => {
									//   return ele._id === props.asset.parent_asset
									// })}
									sensorNumber={
										device?.sensor_number !== undefined
											? device.sensor_number
											: 'NA'
									}
									setDisConnectedSensorsList={setDisConnectedSensorsList}
									disConnectedSensorsList={disConnectedSensorsList}
									device={device}
									asset={asset}
									correspondingCubes={correspondingCubes}
									connectionStatusTags={connectionStatusTags}
									currentPlantAssets={currentPlantAssets}
								/>
							)
						})
				})}

			{disConnectedSensorsList && disConnectedSensorsList.length === 0 ? (
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							height: '70vh',
							width: '93%'
						}}
					>
						<CircularProgress style={{ color: 'white' }} />
						<p
							style={{ color: 'white', marginTop: '20px', fontSize: '1.2rem' }}
						>
							Fetching tags from cube
						</p>
					</div>
				</>
			) : (
				<DisconnectedSensorsTable
					setDisConnectedSensorsList={setDisConnectedSensorsList}
					disConnectedSensorsList={disConnectedSensorsList}
				/>
			)}
		</ErrorBoundary>
	)
}

export default DisconnectedSensors

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import { GREEN, RED } from '../../Utils/UtilFunctions/constant'

function WQMSCsvExportAlert({ open, handleClose, alert }: any) {
	const { blobUrl } = alert
	const isValidUrl = blobUrl?.includes('https')
	return (
		<Modal open={open} onClose={handleClose} closeAfterTransition>
			<Fade in={open}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						position: 'absolute' as const,
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: window?.innerWidth < 400 ? '300px' : '550px',
						boxShadow: '24',
						padding: '16px 40px',
						color: 'white',
						borderRadius: '12px',
						backgroundColor: isValidUrl ? GREEN : RED,
						border: isValidUrl ? GREEN : RED
					}}
				>
					<div
						style={{
							marginTop: '20px'
						}}
					>
						{isValidUrl ? (
							<CheckCircleIcon sx={{ fontSize: '60px' }} />
						) : (
							<CancelIcon sx={{ fontSize: '60px' }} />
						)}
					</div>


					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black',
								padding: '12px',
								borderRadius: '3px',
								boxShadow: '1px 1px 0px 0px #000000',
								width: '98%',
								margin: '5px'
							}}
						>
							<p style={{ textAlign: 'center', fontSize: '12px' }}>
								WQMS export is completed
							</p>
						</div>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: alert.type === 'Delete' ? '100%' : '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								handleClose()
							}}
							type='button'
						>
							Close
						</button>

						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								window.location.href = blobUrl
								handleClose()
							}}
							type='button'
							disabled={!isValidUrl}
						>
							Download
						</button>
					</div>
				</div>
			</Fade>
		</Modal>
	)
}

export default WQMSCsvExportAlert

import { FC, ReactNode, useRef, useState } from 'react'
import { experimentalStyled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Outlet } from 'react-router-dom'
import Header from '../Header'
import SideNav from '../Sidenav'
import { IconButton, Tooltip } from '@material-ui/core'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as AiIcon } from '../../../src/Icons/AiIcon.svg'
import Axios from '../../Utils/AxiosConfig/axiosConfig'
import CancelIcon from '@mui/icons-material/Cancel'

interface SidebarWithHeaderLayoutProps {
	children?: ReactNode
}

const MainWrapper = experimentalStyled(Box)<{ open: boolean }>(
	({ theme, open }) => `
  flex: 1 1 auto;
  display: flex;
  height: 100%;
  padding-left: ${open ? '320px' : '80px'};`
	//   @media (min-width: ${theme.breakpoints.values.lg}px) {}
)

const MainContent = experimentalStyled(Box)(
	({ theme }) => `
  margin-top: ${theme.header.height};
  flex: 1 1 auto;
  overflow: auto;
  `
)

const SidebarWithHeaderLayout: FC<SidebarWithHeaderLayoutProps> = () => {
	const dispatch = useDispatch()
	const iframeRef = useRef<HTMLIFrameElement>(null)
	const usersList = useSelector((state: any) => state.usersList)
	const hasARCHInteligence =
		usersList.currentUserPermissions.includes('ARCH_INTELLIGENCE')
	const showAIToolTip = usersList.currentUser.show_arch_inteligence_tooltip
	const [open, setOpen] = useState(false)
	const [isIframeLoaded, setIsIframeLoaded] = useState(false)
	// dispatch({ type: 'LOAD_LEVELS' })
	const iframeSrc =
		'https://copilotstudio.microsoft.com/environments/Default-773bda68-87c6-401a-b9a4-3dfb1f92e189/bots/creab_archTicketAssistant/webchat?__version__=2'
	const [openChatbot, setOpenChatbot] = useState(false)

	const toggleCTA = (action: string) => {
		setOpen(action === 'open' ? true : false)
	}

	const handleChatbotClick = () => {
		setOpenChatbot(!openChatbot)
		if (!isIframeLoaded) {
			setIsIframeLoaded(true)
		}
		if (showAIToolTip !== false) {
			Axios.put(`users/${usersList.currentUser._id}`, {
				show_arch_inteligence_tooltip: false
			}).then((response: any) => {
				dispatch({
					type: 'UPDATE_USER',
					payload: response?.data
				})
				dispatch({
					type: 'UPDATE_CURRENT_USER',
					payload: response?.data
				})
			})
		}
	}

	return (
		<>
			{/* <Sidebar /> */}
			<SideNav toggleAction={toggleCTA} />
			<div style={{ display: 'flex', height: '100%' }}>
				<MainWrapper open={open}>
					<Header />
					<MainContent>
						<Outlet />
					</MainContent>
				</MainWrapper>
				{hasARCHInteligence && !openChatbot && (
					<Box
						sx={{
							position: 'fixed',
							bottom: 30,
							right: 30,
							width: { xs: '28%', sm: 'unset' },
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						{showAIToolTip !== false && (
							<Box
								sx={{
									backgroundColor: '#8476EF',
									padding: 1,
									borderRadius: 1,
									boxShadow: 1,
									mb: 1,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}
								onClick={handleChatbotClick}
							>
								<Typography variant='body1'>
									Hi, Im ARCH Intelligence,
									<br /> How can I help you?
								</Typography>
							</Box>
						)}
						<Tooltip arrow title='ARCH AI'>
							<IconButton
								aria-label='delete'
								onClick={handleChatbotClick}
								size='medium'
								style={{
									background: '#8476EF',
									borderRadius: '50%',
									fontSize: '35px'
								}}
							>
								<AiIcon />
							</IconButton>
						</Tooltip>
					</Box>
				)}

				<div
					className='chatbot-container'
					style={{
						height: '100%',
						width: '25%',
						display: openChatbot ? 'flex' : 'none',
						padding: '64px 4px 4px 4px',
						borderRadius: '50px',
						minWidth: '290px',
						position: 'relative'
					}}
				>
					<CancelIcon
						sx={{
							fontSize: '20px',
							position: 'absolute',
							top: '80px',
							right: '20px',
							color: 'white',
							background: 'rgb(11, 85, 106)'
						}}
						onClick={handleChatbotClick}
					/>

					<div style={{ flex: 1 }} />
					{isIframeLoaded && (
						<iframe
							ref={iframeRef}
							src={iframeSrc}
							style={{
								padding: '5px',
								borderRadius: '22px',
								border: 'none',
								height: '100%'
							}}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default SidebarWithHeaderLayout

import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import moment from 'moment'
import 'moment-timezone'
import momentTz from 'moment-timezone'

// Currently we are using cst - central standard time
// const timeZone = "America/Regina";
const timestampFormat = 'MM/DD/yyyy, hh:mm A z'

/**
 * This to get the timestamp
 * @params  first -  Date Object | ISO string
 * @params  second - optional
 *
 **/
const getTimeZoneTimeStamp = (timeZone: any, date: any, options?: any) => {
	return utcToZonedTime(date, timeZone, options).valueOf()
}

/**
 * This to get the user readble format to show in component
 * @params first-  use getTimeZoneTimeStamp()
 * @params second - yyyy-MM-dd HH:mm:ss zzz example
 **/
const getFormatedDate = (
	timeZone: any,
	formatedDate: number,
	formatString?: string
) => {
	const utcTime = zonedTimeToUtc(formatedDate, timeZone)
	const timezoneTime = moment(utcTime)
		?.tz(timeZone)
		?.format(formatString || timestampFormat)
	return timezoneTime
	// return format(formatedDate, formatString || defaultFormat, {
	//   timeZone: timeZone,
	// });
}

//function to append time abbreviation after timestamp
export const appendTimezoneWithoutConversion = (
	date: string,
	plantTZ: string
): string => {
	const formattedDate = moment.utc(date).format('MM/DD/YYYY hh:mm A')
	const timezoneAbbr = momentTz.tz(plantTZ).zoneAbbr()

	return `${formattedDate} ${timezoneAbbr}`
}

export { getFormatedDate, getTimeZoneTimeStamp }

import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { PartialRouteObject } from 'react-router'
import SidebarWithHeaderLayout from '../componets/SideBarWithHeaderLayout'
import SuspenseLoader from '../componets/SuspenseLoader'
import UtilitiesDashboard from '../pages/master/UsecaseDashboard/Utilities'
import SupplyChain from '../pages/master/UsecaseDashboard/SupplyChainTicket'
import TicketDetailsSupplyChain from '../pages/master/UsecaseDashboard/SupplyChainTicket/TicketDetailsSupplyChain'
import DowntimeAlerts from '../pages/master/UsecaseDashboard/SupplyChainTicket/Components/DowntimeAlerts'
import DisconnectedSensors from "../pages/master/UsecaseDashboard/MotorsAndPumpsDashboard/DisconnectedSensors";

const Loader = (Component: any) => {
	const WrappedComponent = (props: any) => (
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	)

	WrappedComponent.displayName = `Loader(${
		Component.displayName || Component.name || 'Component'
	})`

	return WrappedComponent
}

//Master pages
const LocationList = Loader(lazy(() => import('../pages/master/location-list')))
const Usecase = Loader(lazy(() => import('../pages/master/usecase')))
const Pool = Loader(lazy(() => import('../pages/master/pool')))
const Types = Loader(lazy(() => import('../pages/master/types')))
const Mapping = Loader(lazy(() => import('../pages/master/mapping')))
const AssetHierarchy = Loader(
	lazy(() => import('../pages/master/assetHierarchy'))
)
const ExportHistoryTable = Loader(
	lazy(() => import('../pages/maintainence/LogsPage/ExportHistoryTable'))
)

// const PBIReports = Loader(lazy(() => import("../pages/master/PBIReports")));

// const PBIWithAsset = Loader(lazy(() => import("../pages/master/PBIWithAsset")));
// const PBIWithPlant = Loader(lazy(() => import("../pages/master/PBIWithPlant")));
// const PBIWithUsecase = Loader(
//   lazy(() => import("../pages/master/PBIWithUsecase"))
// );

// const ReportMapping = Loader(
//   lazy(() => import("../pages/master/ReportMapping"))
// );

const Unauthorized = Loader(lazy(() => import('../pages/master/Unauthorized')))
// const UserShell = Loader(lazy(() => import("../pages/master/User/index")));

//Maintainence pages
const Tickets = Loader(lazy(() => import('../pages/maintainence/tickets')))
const Notification = Loader(lazy(() => import('../pages/master/notification')))
const TicketDetails = Loader(
	lazy(() => import('../pages/maintainence/ticket-details'))
)
const RemovedLGVTicketDetails = Loader(
	lazy(
		() =>
			import('../pages/master/UsecaseDashboard/RemovedLGV/TicketDetailsPage')
	)
)
const RemovedLGVTicketsList = Loader(
	lazy(
		() => import('../pages/master/UsecaseDashboard/RemovedLGV/AllTicketsPage')
	)
)
const WOTicketDetails = Loader(
	lazy(
		() =>
			import('../pages/maintainence/ticket-details/WOTicket/WOTicketDetails')
	)
)

const NotifyDetails = Loader(
	lazy(() => import('../pages/maintainence/notify-details'))
)
const Profile = Loader(lazy(() => import('../pages/master/User')))
const Logs = Loader(lazy(() => import('../pages/maintainence/LogsPage')))
const MotorsAndPumpsDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/MotorsAndPumpsDashboard'))
)
const WQMSDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/WQMSDashboard'))
)
const ServoDrives = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/ServoDrives'))
)
const AirLeaksDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/AirLeaksMonitoring'))
)

// const RunHoursDashboard = Loader(
// 	lazy(() => import('../pages/master/UsecaseDashboard/RunHours'))
// )

const RunHoursDashboardV2 = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/RunHours V2'))
)

const LgvRunHoursDashboardV2 = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/LgvRunHoursV2'))
)

const RunHoursSummary = Loader(
	lazy(
		() =>
			import(
				'../pages/master/UsecaseDashboard/RunHours V2/RunHoursSummary/RunHoursSummaryPage'
			)
	)
)

const AsepticCCPDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/AsepticCCP'))
)

const InjectionAnalytics = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/InjectionAnalytics'))
)
const InjectionTicketsList = Loader(
	lazy(
		() => import('../pages/master/UsecaseDashboard/InjectionAnalytics/AllTicketsPage')
	)
)
const InjectionTicketsDetails = Loader(
	lazy(
		() => import('../pages/master/UsecaseDashboard/InjectionAnalytics/TicketDetailsPage')
	)
)
const InjectionAnalyticsConfig = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/InjectionAnalytics/ConfigPage'))
)

const AsepticCIP = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/AsepticCIP'))
)

const WashDetails = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/AsepticCIP/WashDetails'))
)

const WashAlertRedirectionPage = Loader(
	lazy(
		() =>
			import('../pages/master/UsecaseDashboard/AsepticCIP/AlertRedirectionPage')
	)
)

const WashTable = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/AsepticCIP/WashTable'))
)

const AsepticCCPTroubleshootDashboard = Loader(
	lazy(
		() =>
			import('../pages/master/UsecaseDashboard/AsepticCCP/TroubleShootParent')
	)
)

const AspepticQualityControlTrends = Loader(
	lazy(
		() =>
			import('../pages/master/UsecaseDashboard/AsepticCCP/QualityControlTrends')
	)
)
const AsepticTransmittersDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/AsepticTransmitters'))
)
const AsepticValvesDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/AsepticValves'))
)
const AssetReportPage = Loader(
	lazy(() => import('../Utils/AssetsReportPage/AssetReportPage'))
)
const EAMSchedulerDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/EAMScheduler'))
)
const SatDashboardPage = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/SatDashboard'))
)
const SatSignOffDashboardPage = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/SATSignOff'))
)
const SatSignOffHistoryDashboardPage = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/SATSignOffHistory'))
)
const GrantUserAccessPage = Loader(
	lazy(() => import('../pages/master/User/GrantAccess'))
)
const InjectionMouldingPage = Loader(
	lazy(() => import('../pages/InjectionMoulding/InjectionCycle'))
)
const TroubleshootingModulePage = Loader(
	lazy(() => import('../pages/InjectionMoulding/TroubleshootingModule'))
)
const SilosPage = Loader(lazy(() => import('../pages/InjectionMoulding/Silos')))
const BlendersPage = Loader(
	lazy(() => import('../pages/InjectionMoulding/Blenders'))
)
const DryersPage = Loader(
	lazy(() => import('../pages/InjectionMoulding/Dryers'))
)
const DryersReportPage = Loader(
	lazy(() => import('../pages/InjectionMoulding/Dryers/subpages/report'))
)
const BlenderReportPage = Loader(
	lazy(() => import('../pages/InjectionMoulding/Blenders/subpages/report'))
)
const HyPETPage = Loader(lazy(() => import('../pages/InjectionMoulding/HyPET')))
const HyCAPPage = Loader(lazy(() => import('../pages/InjectionMoulding/HyCAP')))

const HyPETReportMachineView = Loader(
	lazy(
		() =>
			import(
				'../pages/InjectionMoulding/HyPET/subpages/HyPETReportView/MachineView'
			)
	)
)
const HyPETReportTimeSeriesView = Loader(
	lazy(
		() =>
			import(
				'../pages/InjectionMoulding/HyPET/subpages/HyPETReportView/TimeSeriesView'
			)
	)
)
const HyCAPReportMachineView = Loader(
	lazy(
		() =>
			import(
				'../pages/InjectionMoulding/HyCAP/subpages/HyCAPReportView/MachineView'
			)
	)
)
const HyCAPReportTimeSeriesView = Loader(
	lazy(
		() =>
			import(
				'../pages/InjectionMoulding/HyCAP/subpages/HyCAPReportView/TimeSeriesView'
			)
	)
)

const InjectionMoudlingTroubleshooting = Loader(
	lazy(
		() =>
			import('../pages/InjectionMoulding/TroubleshootingModule/QualityControlTrends')
	)
)

const InjectionMoudlingTroubleshootingOG = Loader(
	lazy(
		() =>
			import('../pages/InjectionMoulding/TroubleshootingModule/TroubleShootParent')
	)
)

const SatSummary = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/SATSummaryPage'))
)

//======= LGV Usecase Routes
const LgvNavigationQuality = Loader(
	lazy(
		() => import('../pages/master/UsecaseDashboard/LgvHealth/NavigationQuality')
	)
)

const HydraulicSystemHealth = Loader(
	lazy(
		() =>
			import('../pages/master/UsecaseDashboard/LgvHealth/HydraulicSystemHealth')
	)
)

const LgvPlsSensors = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/LgvHealth/PLSSensors'))
)

const InverterSystemHealth = Loader(
	lazy(
		() =>
			import('../pages/master/UsecaseDashboard/LgvHealth/InverterSystemHealth')
	)
)
const BatterySystemHealth = Loader(
	lazy(
		() =>
			import('../pages/master/UsecaseDashboard/LgvHealth/BatterySystemHealth')
	)
)
const LgvTicketsList = Loader(
	lazy(
		() => import('../pages/master/UsecaseDashboard/LgvHealth/AllTicketsPage')
	)
)
//======= END

const InspectionSystemDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/AGRInspectionSystem'))
)
const ExceptionsList = Loader(
	lazy(
		() => import('../pages/master/UsecaseDashboard/AsepticCIP/ExceptionsList')
	)
)

const RemovedLGVDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/RemovedLGV'))
)
const WarehouseSystemDashboard = Loader(
	lazy(() => import('../pages/master/UsecaseDashboard/WarehouseSystem'))
)

const BatterySystemHealthTicketDetails = Loader(
	lazy(
		() =>
			import(
				'../pages/master/UsecaseDashboard/LgvHealth/BatterySystemHealth/TicketDetailsPage'
			)
	)
)

const adminRoutes: PartialRouteObject[] = [
	{
		path: '/',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='usecase/motors-and-pumps' replace />
			},
			{
				path: 'asset-management/usecase',
				element: <Usecase />
			},
			{
				path: 'asset-management/pool',
				element: <Pool />
			},
			{
				path: 'asset-management/types',
				// element: <Types />,
				element: <Types />
			},
			{
				path: 'asset-management/mapping',
				// element: <Mapping />,
				element: <Mapping />
			},
			{
				path: 'plants',
				children: [
					{
						path: '*',
						element: <AssetHierarchy />
					}
				]
			}
		]
	},
	{
		path: 'eam-prod-overlay',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'scheduler',
				element: <EAMSchedulerDashboard />
			}
		]
	},
	{
		path: 'sat',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/sat/sat-current/:plantId' />
			},
			{
				path: '/sat/sat-current/:plantId',
				element: <SatDashboardPage />
			},
			{
				path: 'sat-sign-off/:plantId',
				element: <SatSignOffDashboardPage />
			},
			{
				path: 'sat-sign-off-history',
				element: <SatSignOffHistoryDashboardPage />
			},
			{
				path: 'sat-summary',
				element: <SatSummary />
			}
		]
	},
	{
		path: 'locations',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/locations/list' replace />
			},
			{
				path: 'list',
				element: <LocationList />
			}
		]
	},
	{
		path: 'usecase',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'motors-and-pumps',
				element: <MotorsAndPumpsDashboard />
			},
			{
			  path: "motors-and-pumps/disconnectedsensors/:plantId",
			  element: <DisconnectedSensors />,
			},
			{
				path: 'utilities',
				element: <UtilitiesDashboard />
			},
			// {
			// 	path: 'run-hours',
			// 	element: <RunHoursDashboard />
			// },
			{
				path: 'run-hours-pm',
				element: <RunHoursDashboardV2 />
			},
			{
				path: 'lgv-run-hours',
				element: <LgvRunHoursDashboardV2 />
			},
			{
				path: 'run-hours-pm/summary',
				element: <RunHoursSummary />
			},
			{
				path: 'wqms',
				element: <WQMSDashboard />
			},
			{
				path: 'servo-drives',
				element: <ServoDrives />
			},
			{
				path: 'aseptic-ccp',
				element: <AsepticCCPDashboard />
			},
			{
				path: 'aseptic-transmitters',
				element: <AsepticTransmittersDashboard />
			},
			{
				path: 'aseptic-valves',
				element: <AsepticValvesDashboard />
			},
			{
				path: 'air-leaks-monitoring',
				element: <AirLeaksDashboard />
			},
			{
				path: 'aseptic-ccp/troubleshoot',
				element: <AsepticCCPTroubleshootDashboard />
			},
			{
				path: "aseptic-ccp/quality-control-trends",
				element: <AspepticQualityControlTrends />,
			},
			{
				path: 'downtime-request',
				element: <SupplyChain />
			},
			{
				path: 'downtime-request/alerts',
				element: <DowntimeAlerts />
			},
			{
				path: 'downtime-request/list-details/:ticketId',
				element: <TicketDetailsSupplyChain />
			},
			{
				path: 'injection-analytics',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/all-tickets',
				element: <InjectionTicketsList />
			},
			{
				path: 'injection-analytics/ticket-details/:ticketId',
				element: <InjectionTicketsDetails />
			},
			{
				path: 'injection-analytics/config/:reportType',
				element: <InjectionAnalyticsConfig />
			},
			{
				path: 'injection-analytics/cycle-count-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cavity-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cycle-time-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-moulding',
				element: <InjectionMouldingPage />
			},
			{
				path: 'injection-moulding/troubleshooting-module-3',
				element: <TroubleshootingModulePage />
			},
			{
				path: 'injection-moulding/troubleshooting-module-2',
				element: <InjectionMoudlingTroubleshooting />
			},
			{
				path: 'injection-moulding/troubleshooting-module',
				element: <InjectionMoudlingTroubleshootingOG />
			},
			{
				path: 'injection-moulding/silos',
				element: <SilosPage />
			},
			{
				path: 'injection-moulding/blenders',
				element: <BlendersPage />
			},
			{
				path: 'injection-moulding/blenders/:blender/asset-report/machine-view/:assetId',
				element: <BlenderReportPage />
			},
			{
				path: 'injection-moulding/dryers',
				element: <DryersPage />
			},
			{
				path: 'injection-moulding/dryers/:dryer/asset-report/machine-view/:assetId',
				element: <DryersReportPage />
			},
			{
				path: 'injection-moulding/HyPET',
				element: <HyPETPage />
			},
			{
				path: 'injection-moulding/HyPET/:hypet/:hypetchild?/:hypetsubchild?/asset-report/machine-view',
				element: <HyPETReportMachineView />
			},
			{
				path: 'injection-moulding/HyPET/:hypet/:hypetchild?/:hypetsubchild?/asset-report/timeseries-view',
				element: <HyPETReportTimeSeriesView />
			},
			{
				path: 'injection-moulding/HyCAP',
				element: <HyCAPPage />
			},
			{
				path: 'injection-moulding/HyCAP/:hycap/:hycapchild?/asset-report/machine-view',
				element: <HyCAPReportMachineView />
			},
			{
				path: 'injection-moulding/HyCAP/:hycap/:hycapchild?/:hycapsubchild?/asset-report/timeseries-view',
				element: <HyCAPReportTimeSeriesView />
			},
			{
				path: 'aseptic-cip',
				element: <AsepticCIP />
			},
			{
				path: 'aseptic-cip/washtable/:plant',
				element: <WashTable />
			},
			{
				path: 'aseptic-cip/exceptions/:plant',
				element: <ExceptionsList />
			},
			{
				path: 'lgv-nav',
				element: <LgvNavigationQuality />
			},
			{
				path: 'lgv-nav/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'lgv-nav/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'inspection-system',
				element: <InspectionSystemDashboard />
			},
			{
				path: 'lgv-hsh',
				element: <HydraulicSystemHealth />
			},
			{
				path: 'lgv-hsh/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'lgv-hsh/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'lgv-ish',
				element: <InverterSystemHealth />
			},
			{
				path: 'lgv-ish/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'lgv-ish/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'lgv-pls/:assetId?',
				element: <LgvPlsSensors />
			},
			{
				path: 'lgv-pls/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'lgv-bsh/:assetId?',
				element: <BatterySystemHealth />
			},
			{
				path: 'lgv-bsh/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'removed-lgv',
				element: <RemovedLGVDashboard />
			},
			{
				path: 'removed-lgv/ticket-details/:ticketId',
				element: <RemovedLGVTicketDetails />
			},
			{
				path: 'removed-lgv/all-tickets',
				element: <RemovedLGVTicketsList />
			},
			{
				path: 'warehouse-system',
				element: <WarehouseSystemDashboard />
			},
			{
				path: 'lgv-bsh/ticket-details/:ticketId',
				element: <BatterySystemHealthTicketDetails />
			}
		]
	},
	{
		path: 'aseptic-cip',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'wash-alert',
				children: [
					{
						path: ':alertId',
						element: <WashAlertRedirectionPage />
					}
				]
			},
			{
				path: 'wash-details',
				children: [
					{
						path: ':washId',
						element: <WashDetails />
					}
				]
			}
		]
	},
	{
		path: 'usecase',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'motors-and-pumps/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'utilities/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'wqms/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'servo-drives/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'air-leaks-monitoring/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-transmitters/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-valves/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-ccp/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'inspection-system/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'injection-analytics/:dashboard',
				element: <InjectionAnalytics />
			},
			{
				path: 'aseptic-cip',
				element: <AsepticCIP />
			},
			{
				path: 'aseptic-cip/washtable/:plant',
				element: <WashTable />
			},
			{
				path: 'aseptic-cip/exceptions/:plant',
				element: <ExceptionsList />
			},
			{
				path: 'removed-lgv',
				element: <RemovedLGVDashboard />
			},
			{
				path: 'removed-lgv/ticket-details/:ticketId',
				element: <RemovedLGVTicketDetails />
			},
			{
				path: 'removed-lgv/all-tickets',
				element: <RemovedLGVTicketsList />
			},
			{
				path: 'warehouse-system',
				element: <WarehouseSystemDashboard />
			},
			{
				path: 'lgv-bsh/ticket-details/:ticketId',
				element: <BatterySystemHealthTicketDetails />
			}
		]
	},
	{
		path: 'aseptic-cip',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'wash-alert',
				children: [
					{
						path: ':alertId',
						element: <WashAlertRedirectionPage />
					}
				]
			},
			{
				path: 'wash-details',
				children: [
					{
						path: ':washId',
						element: <WashDetails />
					}
				]
			}
		]
	},
	{
		path: 'maintenance',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/maintenance/tickets' replace />
			},
			{
				path: 'tickets',
				element: <Tickets />
			},
			{
				path: 'ticket-details',
				children: [
					{
						path: ':ticketId',
						element: <TicketDetails />
					},
					{
						path: ':ticketId/:messageId',
						element: <TicketDetails />
					},
					{
						path: ':ticketId/woTicket',
						element: <WOTicketDetails />
					}
				]
			},
			{
				path: 'notifications',
				element: <Notification />
			},
			{
				path: 'notification-details',
				children: [
					{
						path: '*',
						element: <NotifyDetails />
					}
				]
			}
		]
	},
	{
		path: 'management',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'export-history',
				element: <ExportHistoryTable />
			},
			{
				path: 'usecase-logs',
				element: <Logs />
			},
			{
				path: 'admin-logs',
				element: <Logs />
			},
			{
				path: 'asset-hierarchy-logs',
				element: <Logs />
			},
			{
				path: 'monarch-logs',
				element: <Logs />
			},
			{
				path: 'profile',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'preferences',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'user-management',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'send-email',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'request-access',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'grant-access',
				children: [
					{
						path: '',
						element: <GrantUserAccessPage />
					}
				]
			}
		]
	}
]

const nonAdminRoutes: PartialRouteObject[] = [
	{
		path: '/',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/usecase/motors-and-pumps' replace />
			},
			{
				path: 'asset-management/usecase',
				element: <Unauthorized />
			},
			{
				path: 'asset-management/pool',
				element: <Unauthorized />
			},
			{
				path: 'asset-management/types',
				// element: <Types />,
				element: <Unauthorized />
			},
			{
				path: 'asset-management/mapping',
				// element: <Mapping />,
				element: <Unauthorized />
			},

			{
				path: 'plants',
				children: [
					{
						path: '*',
						element: <AssetHierarchy />
					}
				]
			},
			{
				path: '*',
				element: <Unauthorized />
			}
		]
	},
	{
		path: 'eam-prod-overlay',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='eam-prod-overlay/scheduler' replace />
			},
			{
				path: 'scheduler',
				element: <EAMSchedulerDashboard />
			}
		]
	},
	{
		path: 'sat',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/sat/sat-current/:plantId' />
			},
			{
				path: '/sat/sat-current/:plantId',
				element: <SatDashboardPage />
			},
			{
				path: 'sat-sign-off/:plantId',
				element: <SatSignOffDashboardPage />
			},
			{
				path: 'sat-sign-off-history',
				element: <SatSignOffHistoryDashboardPage />
			},
			{
				path: 'sat-summary',
				element: <SatSummary />
			}
		]
	},
	{
		path: 'locations',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/locations/list' replace />
			},
			{
				path: 'list',
				element: <LocationList />
			}
		]
	},
	{
		path: 'usecase',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'motors-and-pumps',
				element: <MotorsAndPumpsDashboard />
			},
			{
			  path: "motors-and-pumps/disconnectedsensors/:plantId",
			  element: <DisconnectedSensors />,
			},
			{
				path: 'utilities',
				element: <UtilitiesDashboard />
			},
			// {
			// 	path: 'run-hours',
			// 	element: <RunHoursDashboard />
			// },
			{
				path: 'run-hours-pm',
				element: <RunHoursDashboardV2 />
			},
			{
				path: 'run-hours-pm/summary',
				element: <RunHoursSummary />
			},
			{
				path: 'wqms',
				element: <WQMSDashboard />
			},
			{
				path: 'servo-drives',
				element: <ServoDrives />
			},
			{ path: 'aseptic-ccp', element: <AsepticCCPDashboard /> },
			{
				path: 'aseptic-transmitters',
				element: <AsepticTransmittersDashboard />
			},
			{
				path: 'aseptic-valves',
				element: <AsepticValvesDashboard />
			},
			{
				path: 'air-leaks-monitoring',
				element: <AirLeaksDashboard />
			},
			{
				path: 'aseptic-ccp/list-details/:ticketId',
				element: <TicketDetailsSupplyChain />
			},
			{
				path: 'downtime-request',
				element: <SupplyChain />
			},
			{
				path: 'downtime-request/alerts',
				element: <DowntimeAlerts />
			},
			{
				path: 'downtime-request/list-details/:ticketId',
				element: <TicketDetailsSupplyChain />
			},
			{
				path: 'aseptic-cip',
				element: <AsepticCIP />
			},
			{
				path: 'aseptic-cip/washtable/:plant',
				element: <WashTable />
			},
			{
				path: 'aseptic-cip/exceptions/:plant',
				element: <ExceptionsList />
			},
			{
				path: 'injection-analytics',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/config/:reportType',
				element: <InjectionAnalyticsConfig />
			},
			{
				path: 'injection-analytics/cycle-count-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cavity-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cycle-time-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'inspection-system',
				element: <InspectionSystemDashboard />
			},
			{
				path: 'injection-moulding',
				element: <InjectionMouldingPage />
			},
			{
				path: 'lgv-nav',
				element: <LgvNavigationQuality />
			},
			{
				path: 'lgv-nav/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'lgv-nav/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'lgv-hsh',
				element: <HydraulicSystemHealth />
			},
			{
				path: 'lgv-hsh/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'lgv-hsh/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'lgv-ish',
				element: <InverterSystemHealth />
			},
			{
				path: 'lgv-ish/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'lgv-ish/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'lgv-pls/:assetId?',
				element: <LgvPlsSensors />
			},
			{
				path: 'lgv-pls/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'lgv-bsh/:assetId?',
				element: <BatterySystemHealth />
			},
			{
				path: 'lgv-bsh/all-tickets',
				element: <LgvTicketsList />
			},
			{
				path: 'removed-lgv',
				element: <RemovedLGVDashboard />
			},
			{
				path: 'removed-lgv/ticket-details/:ticketId',
				element: <RemovedLGVTicketDetails />
			},
			{
				path: 'removed-lgv/all-tickets',
				element: <RemovedLGVTicketsList />
			},
			{
				path: 'warehouse-system',
				element: <WarehouseSystemDashboard />
			},
			{
				path: 'lgv-bsh/ticket-details/:ticketId',
				element: <BatterySystemHealthTicketDetails />
			}
		]
	},
	{
		path: 'aseptic-cip',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'wash-alert',
				children: [
					{
						path: ':alertId',
						element: <WashAlertRedirectionPage />
					}
				]
			},
			{
				path: 'wash-details',
				children: [
					{
						path: ':washId',
						element: <WashDetails />
					}
				]
			}
		]
	},
	{
		path: 'usecase',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'motors-and-pumps/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'utilities/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'wqms/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'servo-drives/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'air-leaks-monitoring/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-transmitters/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-valves/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-ccp/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'inspection-system/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-cip',
				element: <AsepticCIP />
			},
			{
				path: 'aseptic-cip/washtable/:plant',
				element: <WashTable />
			},
			{
				path: 'aseptic-cip/exceptions/:plant',
				element: <ExceptionsList />
			},
			{
				path: 'injection-analytics',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/config/:reportType',
				element: <InjectionAnalyticsConfig />
			},
			{
				path: 'injection-analytics/cycle-count-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cavity-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cycle-time-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'removed-lgv',
				element: <RemovedLGVDashboard />
			},
			{
				path: 'removed-lgv/ticket-details/:ticketId',
				element: <RemovedLGVTicketDetails />
			},
			{
				path: 'removed-lgv/all-tickets',
				element: <RemovedLGVTicketsList />
			},
			{
				path: 'warehouse-system',
				element: <WarehouseSystemDashboard />
			},
			{
				path: 'lgv-bsh/ticket-details/:ticketId',
				element: <BatterySystemHealthTicketDetails />
			}
		]
	},
	{
		path: 'aseptic-cip',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'wash-alert',
				children: [
					{
						path: ':alertId',
						element: <WashAlertRedirectionPage />
					}
				]
			},
			{
				path: 'wash-details',
				children: [
					{
						path: ':washId',
						element: <WashDetails />
					}
				]
			}
		]
	},
	{
		path: 'maintenance',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/tickets/lists' replace />
			},
			{
				path: 'tickets',
				element: <Tickets />
			},
			{
				path: 'ticket-details',
				children: [
					{
						path: ':ticketId',
						element: <TicketDetails />
					},
					{
						path: ':ticketId/:messageId',
						element: <TicketDetails />
					}
				]
			},
			{
				path: 'notifications',
				element: <Notification />
			},
			{
				path: 'notification-details',
				children: [
					{
						path: '*',
						element: <NotifyDetails />
					}
				]
			}
		]
	},
	{
		path: 'management',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'profile',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'preferences',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'user-management',
				children: [
					{
						path: '',
						element: <Unauthorized />
					}
				]
			},
			{
				path: 'request-access',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'grant-access',
				children: [
					{
						path: '',
						element: <GrantUserAccessPage />
					}
				]
			}
		]
	}
]
// DEVELOPER ROUTES STARTS HERE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const developerRoutes: PartialRouteObject[] = [
	{
		path: '/',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='usecase/motors-and-pumps' replace />
			},
			{
				path: 'asset-management/usecase',
				element: <Usecase />
			},
			{
				path: 'asset-management/pool',
				element: <Pool />
			},
			{
				path: 'asset-management/types',
				// element: <Types />,
				element: <Types />
			},
			{
				path: 'asset-management/mapping',
				// element: <Mapping />,
				element: <Mapping />
			},
			{
				path: 'plants',
				children: [
					{
						path: '*',
						element: <AssetHierarchy />
					}
				]
			}
		]
	},
	{
		path: 'eam-prod-overlay',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='eam-prod-overlay/scheduler' replace />
			},
			{
				path: 'scheduler',
				element: <EAMSchedulerDashboard />
			}
		]
	},
	{
		path: 'locations',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/locations/list' replace />
			},
			{
				path: 'list',
				element: <LocationList />
			}
		]
	},
	{
		path: 'usecase',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'motors-and-pumps',
				element: <MotorsAndPumpsDashboard />
			},
			{
			  path: "motors-and-pumps/disconnectedsensors/:plantId",
			  element: <DisconnectedSensors />,
			},
			{
				path: 'utilities',
				element: <UtilitiesDashboard />
			},
			// {
			// 	path: 'run-hours',
			// 	element: <RunHoursDashboard />
			// },
			{
				path: 'run-hours-pm',
				element: <RunHoursDashboardV2 />
			},
			{
				path: 'run-hours-pm/summary',
				element: <RunHoursSummary />
			},
			{
				path: 'wqms',
				element: <WQMSDashboard />
			},
			{
				path: 'servo-drives',
				element: <ServoDrives />
			},
			{
				path: 'aseptic-ccp',
				element: <AsepticCCPDashboard />
			},
			{
				path: 'aseptic-transmitters',
				element: <AsepticTransmittersDashboard />
			},
			{
				path: 'aseptic-valves',
				element: <AsepticValvesDashboard />
			},
			{
				path: 'air-leaks-monitoring',
				element: <AirLeaksDashboard />
			},
			{
				path: 'aseptic-ccp/troubleshoot',
				element: <AsepticCCPTroubleshootDashboard />
			},
			{
				path: 'downtime-request',
				element: <SupplyChain />
			},
			{
				path: 'downtime-request/alerts',
				element: <DowntimeAlerts />
			},
			{
				path: 'downtime-request/list-details/:ticketId',
				element: <TicketDetailsSupplyChain />
			},
			{
				path: 'injection-analytics',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/config/:reportType',
				element: <InjectionAnalyticsConfig />
			},
			{
				path: 'aseptic-cip',
				element: <AsepticCIP />
			},
			{
				path: 'aseptic-cip/washtable/:plant',
				element: <WashTable />
			},
			{
				path: 'aseptic-cip/exceptions/:plant',
				element: <ExceptionsList />
			},
			{
				path: 'injection-analytics',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/config/:reportType',
				element: <InjectionAnalyticsConfig />
			},
			{
				path: 'injection-analytics/cycle-count-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cavity-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cycle-time-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-moulding',
				element: <InjectionMouldingPage />
			},
			{
				path: 'inspection-system',
				element: <InspectionSystemDashboard />
			},
			{
				path: 'removed-lgv',
				element: <RemovedLGVDashboard />
			},
			{
				path: 'removed-lgv/ticket-details/:ticketId',
				element: <RemovedLGVTicketDetails />
			},
			{
				path: 'removed-lgv/all-tickets',
				element: <RemovedLGVTicketsList />
			},
			{
				path: 'warehouse-system',
				element: <WarehouseSystemDashboard />
			},
			{
				path: 'lgv-bsh/ticket-details/:ticketId',
				element: <BatterySystemHealthTicketDetails />
			}
		]
	},
	{
		path: 'aseptic-cip',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'wash-alert',
				children: [
					{
						path: ':alertId',
						element: <WashAlertRedirectionPage />
					}
				]
			},
			{
				path: 'wash-details',
				children: [
					{
						path: ':washId',
						element: <WashDetails />
					}
				]
			}
		]
	},
	{
		path: 'usecase',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'motors-and-pumps/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'utilities/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'wqms/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'servo-drives/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'air-leaks-monitoring/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-transmitters/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-valves/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'aseptic-ccp/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'inspection-system/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'injection-analytics/:dashboard',
				element: <InjectionAnalytics />
			},
			{
				path: 'aseptic-cip',
				element: <AsepticCIP />
			},
			{
				path: 'aseptic-cip/washtable/:plant',
				element: <WashTable />
			},
			{
				path: 'aseptic-cip/exceptions/:plant',
				element: <ExceptionsList />
			},
			{
				path: 'injection-analytics',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/config/:reportType',
				element: <InjectionAnalyticsConfig />
			},
			{
				path: 'injection-analytics/cycle-count-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cavity-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cycle-time-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'removed-lgv',
				element: <RemovedLGVDashboard />
			},
			{
				path: 'removed-lgv/ticket-details/:ticketId',
				element: <RemovedLGVTicketDetails />
			},
			{
				path: 'removed-lgv/all-tickets',
				element: <RemovedLGVTicketsList />
			},
			{
				path: 'warehouse-system',
				element: <WarehouseSystemDashboard />
			},
			{
				path: 'lgv-bsh/ticket-details/:ticketId',
				element: <BatterySystemHealthTicketDetails />
			}
		]
	},
	{
		path: 'aseptic-cip',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'wash-alert',
				children: [
					{
						path: ':alertId',
						element: <WashAlertRedirectionPage />
					}
				]
			},
			{
				path: 'wash-details',
				children: [
					{
						path: ':washId',
						element: <WashDetails />
					}
				]
			}
		]
	},
	{
		path: 'maintenance',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/maintenance/tickets' replace />
			},
			{
				path: 'tickets',
				element: <Tickets />
			},
			{
				path: 'ticket-details',
				children: [
					{
						path: ':ticketId',
						element: <TicketDetails />
					},
					{
						path: ':ticketId/:messageId',
						element: <TicketDetails />
					}
				]
			},
			{
				path: 'notifications',
				element: <Notification />
			},
			{
				path: 'notification-details',
				children: [
					{
						path: '*',
						element: <NotifyDetails />
					}
				]
			}
		]
	},
	{
		path: 'management',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'export-history',
				element: <ExportHistoryTable />
			},
			{
				path: 'usecase-logs',
				element: <Logs />
			},
			{
				path: 'admin-logs',
				element: <Logs />
			},
			{
				path: 'asset-hierarchy-logs',
				element: <Logs />
			},
			{
				path: 'monarch-logs',
				element: <Logs />
			},
			{
				path: 'profile',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'preferences',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'send-email',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'request-access',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'grant-access',
				children: [
					{
						path: '',
						element: <GrantUserAccessPage />
					}
				]
			}
		]
	}
]

// Supply chain admin routes
const supplyChainAdminRoutes: PartialRouteObject[] = [
	{
		path: '/',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/usecase/downtime-request' replace />
			},
			{
				path: 'asset-management/usecase',
				element: <Unauthorized />
			},
			{
				path: 'asset-management/pool',
				element: <Unauthorized />
			},
			{
				path: 'asset-management/types',
				// element: <Types />,
				element: <Unauthorized />
			},
			{
				path: 'asset-management/mapping',
				// element: <Mapping />,
				element: <Unauthorized />
			},
			// {
			//   path: "eam-prod-overlay",
			//   element: <SidebarWithHeaderLayout />,
			//   children: [
			//     {
			//       path: "scheduler",
			//       element: <Unauthorized />,
			//     },
			//     {
			//       path: "scheduler/view-asset/:woId",
			//       element: <Unauthorized />,
			//     },
			//   ],
			// },
			{
				path: 'plants',
				children: [
					{
						path: '*',
						element: <AssetHierarchy />
					}
				]
			},
			{
				path: '*',
				element: <Unauthorized />
			}
		]
	},
	{
		path: 'sat',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/sat/sat-current/:plantId' />
			},
			{
				path: '/sat/sat-current/:plantId',
				element: <Unauthorized />
			},
			{
				path: 'sat-sign-off/:plantId',
				element: <Unauthorized />
			},
			{
				path: 'sat-sign-off-history',
				element: <Unauthorized />
			},
			{
				path: 'sat-summary',
				element: <Unauthorized />
			}
		]
	},
	{
		path: 'locations',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/locations/list' replace />
			},
			{
				path: 'list',
				element: <LocationList />
			}
		]
	},
	{
		path: 'usecase',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'motors-and-pumps',
				element: <MotorsAndPumpsDashboard />
			},
			{
			  path: "motors-and-pumps/disconnectedsensors/:plantId",
			  element: <DisconnectedSensors />,
			},
			{
				path: 'utilities',
				element: <UtilitiesDashboard />
			},
			{
				path: 'run-hours-pm',
				element: <RunHoursDashboardV2 />
			},
			{
				path: 'run-hours-pm/summary',
				element: <RunHoursSummary />
			},
			{
				path: 'wqms',
				element: <WQMSDashboard />
			},
			{
				path: 'servo-drives',
				element: <ServoDrives />
			},
			{ path: 'aseptic-ccp', element: <AsepticCCPDashboard /> },
			{
				path: 'aseptic-ccp/troubleshoot',
				element: <AsepticCCPTroubleshootDashboard />
			},
			{
				path: 'aseptic-transmitters',
				element: <AsepticTransmittersDashboard />
			},
			{
				path: 'aseptic-valves',
				element: <AsepticValvesDashboard />
			},
			{
				path: 'air-leaks-monitoring',
				element: <AirLeaksDashboard />
			},
			{
				path: 'aseptic-ccp/list-details/:ticketId',
				element: <TicketDetailsSupplyChain />
			},
			{
				path: 'downtime-request',
				element: <SupplyChain />
			},
			{
				path: 'downtime-request/alerts',
				element: <DowntimeAlerts />
			},
			{
				path: 'downtime-request/list-details/:ticketId',
				element: <TicketDetailsSupplyChain />
			},
			{
				path: 'aseptic-cip',
				element: <AsepticCIP />
			},
			{
				path: 'aseptic-cip/washtable/:plant',
				element: <WashTable />
			},
			{
				path: 'aseptic-cip/exceptions/:plant',
				element: <ExceptionsList />
			},
			{
				path: 'injection-analytics',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/config/:reportType',
				element: <InjectionAnalyticsConfig />
			},
			{
				path: 'injection-analytics/cycle-count-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cavity-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'injection-analytics/cycle-time-monitoring',
				element: <InjectionAnalytics />
			},
			{
				path: 'inspection-system',
				element: <InspectionSystemDashboard />
			},
			{
				path: 'injection-moulding',
				element: <InjectionMouldingPage />
			},
			{
				path: 'lgv-nav',
				element: <LgvNavigationQuality />
			},
			{
				path: 'lgv-nav/:assetId',
				element: <AssetReportPage />
			},
			{
				path: 'lgv-hsh',
				element: <HydraulicSystemHealth />
			},
			{
				path: 'lgv-hsh/:assetId',
				element: <HydraulicSystemHealth />
			},
			{
				path: 'lgv-pls/:assetId?',
				element: <LgvPlsSensors />
			},
			{
				path: 'lgv-ish/:assetId?',
				element: <InverterSystemHealth />
			},
			{
				path: 'lgv-bsh/:assetId?',
				element: <BatterySystemHealth />
			},
			{
				path: 'removed-lgv',
				element: <RemovedLGVDashboard />
			},
			{
				path: 'removed-lgv/ticket-details/:ticketId',
				element: <RemovedLGVTicketDetails />
			},
			{
				path: 'removed-lgv/all-tickets',
				element: <RemovedLGVTicketsList />
			},
			{
				path: 'warehouse-system',
				element: <WarehouseSystemDashboard />
			},
			{
				path: 'lgv-bsh/ticket-details/:ticketId',
				element: <BatterySystemHealthTicketDetails />
			}
		]
	},
	{
		path: 'aseptic-cip',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'wash-alert',
				children: [
					{
						path: ':alertId',
						element: <WashAlertRedirectionPage />
					}
				]
			},
			{
				path: 'wash-details',
				children: [
					{
						path: ':washId',
						element: <Unauthorized />
					}
				]
			}
		]
	},
	{
		path: 'usecase',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'motors-and-pumps/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'utilities/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'wqms/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'servo-drives/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'air-leaks-monitoring/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'inspection-system/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'aseptic-transmitters/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'aseptic-valves/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'aseptic-ccp/:assetId',
				element: <Unauthorized />
			},
			{
				path: 'aseptic-cip',
				element: <Unauthorized />
			},
			{
				path: 'aseptic-cip/washtable/:plant',
				element: <Unauthorized />
			},
			{
				path: 'aseptic-cip/exceptions/:plant',
				element: <Unauthorized />
			},
			{
				path: 'injection-analytics',
				element: <Unauthorized />
			},
			{
				path: 'injection-analytics/config/:reportType',
				element: <Unauthorized />
			},
			{
				path: 'injection-analytics/cycle-count-monitoring',
				element: <Unauthorized />
			},
			{
				path: 'injection-analytics/cavity-monitoring',
				element: <Unauthorized />
			},
			{
				path: 'injection-analytics/cycle-time-monitoring',
				element: <Unauthorized />
			}
		]
	},
	{
		path: 'aseptic-cip',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'wash-alert',
				children: [
					{
						path: ':alertId',
						element: <WashAlertRedirectionPage />
					}
				]
			},
			{
				path: 'wash-details',
				children: [
					{
						path: ':washId',
						element: <Unauthorized />
					}
				]
			}
		]
	},
	{
		path: 'maintenance',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Navigate to='/tickets/lists' replace />
			},
			{
				path: 'tickets',
				element: <Unauthorized />
			},
			{
				path: 'ticket-details',
				children: [
					{
						path: ':ticketId',
						element: <Unauthorized />
					},
					{
						path: ':ticketId/:messageId',
						element: <Unauthorized />
					}
				]
			},
			{
				path: 'notifications',
				element: <Notification />
			},
			{
				path: 'notification-details',
				children: [
					{
						path: '*',
						element: <NotifyDetails />
					}
				]
			}
		]
	},
	{
		path: 'management',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'profile',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'preferences',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'user-management',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'request-access',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'grant-access',
				children: [
					{
						path: '',
						element: <GrantUserAccessPage />
					}
				]
			}
		]
	}
]

const unauthorizedRoutes: PartialRouteObject[] = [
	{
		path: '/',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: '',
				element: <Unauthorized />
			},
			{
				path: '*',
				element: <Unauthorized />
			}
		]
	},
	{
		path: 'management',
		element: <SidebarWithHeaderLayout />,
		children: [
			{
				path: 'profile',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'request-access',
				children: [
					{
						path: '',
						element: <Profile />
					}
				]
			},
			{
				path: 'grant-access',
				children: [
					{
						path: '',
						element: <GrantUserAccessPage />
					}
				]
			}
		]
	}
]

export {
	adminRoutes,
	nonAdminRoutes,
	unauthorizedRoutes,
	developerRoutes,
	supplyChainAdminRoutes
}


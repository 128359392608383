import * as React from 'react'
import { useSelector } from 'react-redux'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Popper } from '@material-ui/core'
import { ExpandMore } from '@mui/icons-material'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	MenuItem,
	Tooltip,
	Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import moment from 'moment'
import Axios from '../../Utils/AxiosConfig/axiosConfig'
import CustomScrollbars from '../CustomScrollbars/CustomScrollbars'
import { MenuDirectory } from './MenuDirectory'
import { env } from '../../env'
import { Directory, Menu, SideNavProps } from './SideNav-interfaces/sidenav.interface'

// Icon Imports
import { ReactComponent as AlertsIcon } from './SideNav-assets/Alerts.svg'
import { ReactComponent as CollapseIcon } from './SideNav-assets/Collapse.svg'
import { ReactComponent as ExpandIcon } from './SideNav-assets/Expand.svg'
import { ReactComponent as EAMIcon } from './SideNav-assets/EAM.svg'
import { ReactComponent as SATIcon } from './SideNav-assets/SAT.svg'
import { ReactComponent as LGVHealthMonitorIcon } from './SideNav-assets/LGVHealthMonitor.svg'
import { ReactComponent as LocationIcon } from './SideNav-assets/Location.svg'
import { ReactComponent as LGVIcon } from './SideNav-assets/LGV.svg'
import { ReactComponent as ProductionLineIcon } from './SideNav-assets/ProductionLine.svg'
import { ReactComponent as ManagementIcon } from './SideNav-assets/Management.svg'
import { ReactComponent as MonarchInitialIcon } from './SideNav-assets/MonarchInitial.svg'
import { ReactComponent as UsecaseIcon } from './SideNav-assets/Usecase.svg'
import { ReactComponent as AirLeaksIcon } from './SideNav-assets/AirLeaks.svg'
import { ReactComponent as AGRInspectionSystemIcon } from './SideNav-assets/AGRInspectionSystem.svg'
import { ReactComponent as AsepticBatchRoomCCPIcon } from './SideNav-assets/AsepticBatchRoomCCP.svg'
import { ReactComponent as AsepticProcessRoomCCPIcon } from './SideNav-assets/AsepticProcessRoomCCP.svg'
import { ReactComponent as AsepticValvesIcon } from './SideNav-assets/AsepticValves.svg'
import { ReactComponent as AsepticCIPIcon } from './SideNav-assets/AsepticCIP.svg'
import { ReactComponent as MotorsAndPumpsIcon } from './SideNav-assets/MotorsAndPumps.svg'
import { ReactComponent as InjectionAnalyticsIcon } from './SideNav-assets/InjectionAnalytics.svg'
import { ReactComponent as InjectionMouldingIcon } from './SideNav-assets/InjectionMoulding.svg'
import { ReactComponent as RunHoursIcon } from './SideNav-assets/RunHours.svg'
import { ReactComponent as ServoDrivesIcon } from './SideNav-assets/ServoDrives.svg'
import { ReactComponent as DowntimeRequestsIcon } from './SideNav-assets/DowntimeRequests.svg'
import { ReactComponent as UtilitiesIcon } from './SideNav-assets/Utilities.svg'
import { ReactComponent as WQMSIcon } from './SideNav-assets/WQMS.svg'
import { ReactComponent as TicketsIcon } from './SideNav-assets/Tickets.svg'
import { ReactComponent as MotorsIcon } from './SideNav-assets/Motors.svg'
import { ReactComponent as MappingIcon } from './SideNav-assets/Mapping.svg'
import { ReactComponent as PoolIcon } from './SideNav-assets/Pool.svg'
import { ReactComponent as FacilitiesIcon } from './SideNav-assets/Facilities.svg'
import { ReactComponent as TypesIcon } from './SideNav-assets/Types.svg'
import { ReactComponent as LGVNavIcon } from './SideNav-assets/LGVNav.svg'
import { ReactComponent as LGVHSHIcon } from './SideNav-assets/LGVHSH.svg'
import { ReactComponent as LGVISHIcon } from './SideNav-assets/LGVISH.svg'
import { ReactComponent as LGVPLSIcon } from './SideNav-assets/LGVPLS.svg'
import { ReactComponent as LGVBatteryIcon } from './SideNav-assets/LGVBattery.svg'
import { ReactComponent as WarehouseIconIcon } from './SideNav-assets/WarehouseSystem.svg'
import { ReactComponent as RemovedLGVIcon } from './SideNav-assets/Removed-LGV.svg'

const drawerWidth = 320

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	backgroundColor: theme.palette.background.default,
	overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	backgroundColor: theme.palette.background.paper,
	width: '80px'
})

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar
}))

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}))

const useStyles = makeStyles((theme: Theme) => ({
	nested: {
		paddingLeft: theme.spacing(2)
	},
	accordion: {
		width: '100%',
		height: 'auto',
		backgroundColor: theme.palette.background.default
	},
	accordionDetails: {
		flexDirection: 'column',
		backgroundColor: theme.palette.background.default,
		'& :hover': {
			backgroundColor: theme.palette.background.paper,
			color: 'white',
			borderRadius: '5px',
			textDecoration: 'none'
		}
	},
	itemSelected: {
		backgroundColor: theme.palette.background.paper,
		color: 'white',
		borderRadius: '5px',
		textDecoration: 'none'
	},
	menuTitle: {
		fontSize: '17px',
		padding: '5px 10px 15px 10px',
		fontWeight: '600'
	},
	popperCard: {
		marginLeft: '20px',
		minWidth: '330px',
		borderRadius: '5px',
		zIndex: 100000
	},
	popperCardInnerStyle: {
		backgroundColor: theme.palette.background.default,
		padding: '20px 20px 10px 20px',
		maxHeight: '750px',
		overflow: 'auto',
		width: 'inherit',
		borderRadius: '10px',
		boxShadow: '0px 0px 0px 1px rgb(0 0 0 / 20%)'
	},
	popperItem: {
		width: 'inherit',
		color: 'white',
		'& :hover': {
			backgroundColor: theme.palette.background.paper,
			color: 'white'
		}
	},
	iconSection: {
		padding: 10,
		'& :hover': {
			backgroundColor: theme.palette.background.default
		}
	},
	backgroundColorDefault: {
		backgroundColor: theme.palette.background.default
	},
	iconSectionButtons: {
		minHeight: 48,
		justifyContent: 'center',
		px: 2.5
	},
	menuItem: {
		width: 'inherit',
		height: '40px',
		padding: '15px 0px'
	}
}))

const SideNav: React.FC<SideNavProps> = ({toggleAction}) => {
	const classes = useStyles()
	const theme = useTheme()
	const location = useLocation()
	const usersList = useSelector((state: any) => state.usersList)
	const levelsList = useSelector((state: any) => state.levelsList)
	const usecaseList = useSelector((state: any) => state.usecaseList)
	const seedsList = useSelector((state: any) => state.seedsList)
	const [open, setOpen] = React.useState(false)
	const [anchorElement, setAnchorElement] = React.useState<any>(null)
	const [hoveredIndex, setHoveredIndex] = React.useState(-1)
	const [clickedIndex, setClickedIndex] = React.useState(-1)
	const [options, setOptions] = React.useState(MenuDirectory)
	const drawerRef = React.useRef<any>(null)
	const initialRender = React.useRef<boolean>(true)
	const [screenWidth, setScreenWidth] = React.useState(window.matchMedia('(min-width: 2000px)').matches)
	const currentPermissions = seedsList.seeds.find(
    (ele: any) => ele.seed_key_name === "EAMRoleConfigData"
    )?.seed_data_value;

	const satConfigSeed = seedsList.seeds.find(
		(ele: any) => ele.seed_key_name === 'SATConfigData'
	)?.seed_data_value

	/**
	 * @function handleDrawerOpen - toggle the side nav to open.
	 * 
	 * Trigger toggleAction method - will pass the action param to parent component. So that the width changes on toggle
	 */
	const handleDrawerOpen = () => {
		toggleAction('open')
		setOpen(true)
	}

	/**
	 * @function handleDrawerClose - toggle the side nav to default view i.e Icons view.
	 * 
	 * Trigger toggleAction method - will pass the action param to parent component. So that the width changes on toggle
	 * onHoverEnds method will reset the necessary useStates
	 */
	const handleDrawerClose = () => {
		toggleAction('close')
		setOpen(false)
		setSelectionCTA()
		onHoverEnds()
	}

	/**
	 * @function onHoverStarts - to set the MenuItems for Popper component and help to capture the position for AnchorElement 
	 * @param event AnchorElement
	 * @param index number - the index of the item hovered from the MenuDirectory
	 */
	const onHoverStarts = (event: any, index: number) => {
		setHoveredIndex(index)
		setAnchorElement(event.currentTarget)
	}

	/**
	 * @function onHoverEnds - to reset the useStates on cursor leaves from the side nav area
	 * 
	 */
	const onHoverEnds = () => {
		setHoveredIndex(-1)
		setAnchorElement(null)
	}

	/**
	 * @function getIcons - capture the IconComponent from the params - The Icon name will be present in the MenuDirectory
	 * @param iconName string - name of the icon
	 * @returns React Icon Component 
	 */
	const getIcons = (iconName: string) => {
		switch (iconName) {
			case 'Usecase':
				return <UsecaseIcon />
			case 'Alerts':
				return <AlertsIcon />
			case 'EAM':
				return <EAMIcon />
			case 'Location':
				return <LocationIcon />
			case 'SAT':
				return <SATIcon />
			case 'LGVHealthMonitor':
				return <LGVHealthMonitorIcon />
			case 'Management':
				return <ManagementIcon />
			case 'AirLeaks':
				return <AirLeaksIcon />
			case 'LGV':
				return <LGVIcon />
			case "ProductionLine":
				return <ProductionLineIcon />
			case 'AGRInspectionSystem':
				return <AGRInspectionSystemIcon />
			case 'AsepticBatchRoomCCP':
				return <AsepticBatchRoomCCPIcon />
			case 'AsepticProcessRoomCCP':
				return <AsepticProcessRoomCCPIcon />
			case 'AsepticValves':
				return <AsepticValvesIcon />
			case 'AsepticCIP':
				return <AsepticCIPIcon />
			case 'MotorsAndPumps':
				return <MotorsAndPumpsIcon />
			case 'InjectionAnalytics':
				return <InjectionAnalyticsIcon />
			case 'InjectionMoulding':
				return <InjectionMouldingIcon />
			case 'RunHours':
				return <RunHoursIcon />
			case 'ServoDrives':
				return <ServoDrivesIcon />
			case 'DowntimeRequests':
				return <DowntimeRequestsIcon />
			case 'Utilities':
				return <UtilitiesIcon />
			case 'WQMS':
				return <WQMSIcon />
			case 'RemovedLGV':
				return <RemovedLGVIcon />
			case 'Warehouse':
				return <WarehouseIconIcon />
			case 'Tickets':
				return <TicketsIcon />
			case 'Motors':
				return <MotorsIcon />
			case 'Mapping':
				return <MappingIcon />
			case 'Pool':
				return <PoolIcon />
			case 'Facilities':
				return <FacilitiesIcon />
			case 'Types':
				return <TypesIcon />
			case 'LGVNav':
				return <LGVNavIcon />
			case 'LGVHSH':
				return <LGVHSHIcon />
			case 'LGVISH':
				return <LGVISHIcon />
			case 'LGVPLS':
				return <LGVPLSIcon />
			case 'LGVBattery':
				return <LGVBatteryIcon />

			default:
				break
		}
	}

	/**
	 * @const SATFinal - To show the menu for SAT, as per the current implementation the menu items are not static but dynamic.
	 * The menu getting added dynamically based on the seed configs with certain conditions.
	 * The code written below to capture the SAT menu other than SAT Summary.
	 * 
	 */
	const SATFinal: Menu[] = [
		...(satConfigSeed
			?.filter((ele: any) => JSON.parse(ele.value).AddNewSAT !== 'Complete')
			?.map((item: any) => {
				const config = JSON.parse(item.value)
				if (config.AddNewSAT !== 'Configuring') {
					const timezone = config?.PlantTimezone
					const currentMoment = moment.tz(timezone)
					const endDateMoment = moment.tz(
						config.SATDates[config.CurrentSATLines[0]].endDateTime,
						timezone
					)

					const isPastDate = currentMoment.isSameOrAfter(endDateMoment)

					return {
						name:
							config.AddNewSAT === 'Pre SAT'
								? `${item.key}- L${config.CurrentSATLines[0].substring(config.CurrentSATLines[0].length - 1)}- Pre SAT`
								: isPastDate
									? `${item.key}- L${config.CurrentSATLines[0].substring(config.CurrentSATLines[0].length - 1)}- Post SAT`
									: config.AddNewSAT === 'Live'
										? `${item.key}- L${config.CurrentSATLines[0].substring(config.CurrentSATLines[0].length - 1)}- Live SAT`
										: `${item.key}- L${config.CurrentSATLines[0].substring(config.CurrentSATLines[0].length - 1)}- Pre SAT`,
						route:
							config.AddNewSAT === 'Configuring'
								? `/sat/sat-current/${item.key}?status=${config.AddNewSAT}`
								: `/sat/sat-current/${item.key}?line=${config.CurrentSATLines[0]}&start=${config.SATDates[config.CurrentSATLines[0]].startDateTime}`,
						icon: 'Motors',
						level_use_code: 'NA',
						isSelected: false,
						isVisible: true
					}
				} else {
					return {
						name: `${item.key}- Pre SAT`,
						route: `/sat/sat-current/${item.key}?status=${config.AddNewSAT}`,
						level_use_code: 'NA',
						icon: 'Motors',
						isSelected: false,
						isVisible: true
					}
				}
			}) || [])
	]

	/**
	 * @function itemClickCTA will call on click of the menu item, updates & resets the boolean values of isOpen, isSelected based on the clicked item.
	 * @param parentIndex number
	 * @param childIndex number - MenuDirectory children property index 
	 */
	const itemClickCTA = (parentIndex: number, childIndex: number) => {
		const updatedOpts = options.map((option: Directory, pIndex: number) => {
			option.isOpen = parentIndex === pIndex ? true : false
			option.children.map((child, cIndex) => child.isSelected = (parentIndex === pIndex) ? (childIndex === cIndex ? true : false) : false)
			return option
		})
		setOptions(updatedOpts)
	}

	/**
	 * @function isEamPlantManagerRole checks the current logged in user has any EAM related roles.
	 * The EAM roles stored in the @variable `currentPermissions`.
	 * This method will not trigger for roles ARCH ADMIN, ARCH Developer roles, by default we need to show EAM for those roles
	 * @param permissions current logged in user permission list
	 * @returns boolean - if any EAM role present returns true
	 */
	const isEamPlantManagerRole = (permissions: any) : boolean => {
		const allHaveName = permissions?.filter(
			(user: string) => !currentPermissions?.includes(user)
		)
		return allHaveName.every(
			(item: string) =>
				item.includes('ARCH_USECASE_EAM') || item.includes('ARCH_USECASE_EAM_')
		)
	}

	/**
	 * @function checkUserHasOnlyDowntimeUsecaseAccess to check the current logged in user has only Downtime Requests use case access. 
	 * @returns boolean
	 */
	const checkUserHasOnlyDowntimeUsecaseAccess = () : boolean => {
		const downtimeUseCaseRoles: string[] = [
			'ARCH_SCM_NPI_ANALYST',
			'ARCH_SCM_CORE_MASTER_PLANNER',
			'ARCH_SCM_FG_PLANNER',
			'ARCH_SCM_SNP_MANAGER',
			'ARCH_SCM_BRAND_MANAGER',
			'ARCH_SCM_CORE_ANALYST',
			'ARCH_SCM_FG_MANAGER',
			'ARCH_SCM_BRAND_PLANNER',
			'ARCH_SCM_PLANT_DIRECTOR',
			'ARCH_SCM_ADMIN'
		]
		let hasOnlyDowntimeUsecaseRole = false
		for (
			let index = 0;
			index < usersList.currentUserPermissions.length;
			index++
		) {
			const element = usersList.currentUserPermissions[index]
			if (downtimeUseCaseRoles.includes(element)) {
				hasOnlyDowntimeUsecaseRole = true
			} else {
				hasOnlyDowntimeUsecaseRole = false
				break
			}
		}
		return hasOnlyDowntimeUsecaseRole
	}

	/**
	 * @function checkUserHasCIPUsecaseAccess to check current logged in user has only CIP use case access
	 * @returns boolean
	 */
	const checkUserHasCIPUsecaseAccess = () : boolean => {
		const CIPUseCaseRoles: string[] = ['ARCH_USECASE_CIP']
		let hasOnlyCIPUsecaseRole = false
		for (
			let index = 0;
			index < usersList.currentUserPermissions.length;
			index++
		) {
			const element = usersList.currentUserPermissions[index]
			if (CIPUseCaseRoles.includes(element)) {
				hasOnlyCIPUsecaseRole = true
			} else {
				hasOnlyCIPUsecaseRole = false
				break
			}
		}
		return hasOnlyCIPUsecaseRole
	}

	/** Enable or Disable Menu items based on user Role logic starts  */

	/**
	 * @function enableAccessForAdminRole will trigger if the user role has ARCH_ADMIN.
	 * 
	 * If the ARCH_ADMIN role present in the `usersList.currentUserPermissions` array, then all other roles will not be considered.
	 * 
	 * This method will enable all the menu options from the MenuDirectory.
	 */
	const enableAccessForAdminRole = () => {
		const indexData = selectOptionsInUIBasedOnRouteChange();
		const updatedOptions = options.map((option: Directory, pIndex: number) => {
			option.isSectionVisible = true
			option.children.forEach((child: Menu) => (child.isVisible = true))
			if(indexData.parentIndex > -1 && indexData.childIndex > -1) {
				if(pIndex === indexData.parentIndex) {
					option.isOpen = true;
					option.children[indexData.childIndex].isSelected = true;
				}
			}
			if (option.title === 'SAT') {
				SATFinal.forEach(sat => {
					if (!option.children.some((c: Menu) => c.route === sat.route)) {
						option.children.push({ ...sat })
					}
				})
			}
			return option
		})
		setOptions(updatedOptions)
	}

	/**
	 * @function enableAccessForDeveloperRole will trigger if the user role has ARCH_DEVELOPER.
	 * 
	 * If the ARCH_DEVELOPER role present in the `usersList.currentUserPermissions` array and there is not ADMIN role present, then all other roles will not be considered.
	 * 
	 * The ARCH_DEVELOPER have access to all the use case, LGV, EAM, Alerts (Tickets), Management (Mapping, Pool and Types) 
	 */
	const enableAccessForDeveloperRole = () => {
		const indexData = selectOptionsInUIBasedOnRouteChange();
		const updatedOptions = options.map((option: Directory, pIndex: number) => {
			switch (option.title) {
				case 'Usecase':
				case 'Run Hours':
				case 'LGV Health Monitor':
					option = { ...filteringUsecase(option) }
					break;
				case 'EAM Prod Overlay':
				case 'Management':
				case 'Alerts':
					option.isSectionVisible = true
					option.children.forEach((child: Menu) => (child.isVisible = true))
					if(indexData.parentIndex > -1 && indexData.childIndex > -1) {
						if(pIndex === indexData.parentIndex) {
							option.isOpen = true;
							option.children[indexData.childIndex].isSelected = true;
						}
					}
					break

				default:
					break
			}
			return option
		})
		setOptions(updatedOptions)
	}

	/**
	 * @function enableAccessForSATLEADRole will trigger if the user role is (only) ARCH_SAT_LEAD
	 * 
	 * This will get all the SAT options and in UI will have only SAT related items.
	 */
	const enableAccessForSATLEADRole = () => {
		const updatedOptions = options.map((option: Directory) => {
			if (option.title === 'SAT') {
				option.isSectionVisible = true
				option.isOpen = true;
				option.children[0].isSelected = true;
				SATFinal.forEach(sat => {
					if (!option.children.some((c: any) => c.route === sat.route)) {
						option.children.push({ ...sat })
					}
				})
			}
			return option
		})
		setOptions(updatedOptions)
	}

	/**
	 * @function enableAccessForOtherRolesAndRoleCombinations will trigger for all the roles [other than ARCH_ADMIN, ARCH_DEVELOPER, ARCH_SAT_LEAD] and role combinations.
	 * 
	 * A map method will iterate through the MenuDirectory and check the role of the current logged in user based on that it'll enable or disable.
	 *  
	 */
	const enableAccessForOtherRolesAndRoleCombinations = () => {
		const indexData = selectOptionsInUIBasedOnRouteChange();
		const updatedOptions = [...options]
		const final = updatedOptions.map((option: any) => {
			if (option.title === 'Usecase' || option.title === 'LGV Health Monitor' || option.title === "Run Hours") {
				option = { ...filteringUsecase(option) }
			} else if (option.title === 'EAM Prod Overlay') {
				option = { ...filterEAM(option) }
			} else if (option.title === 'SAT') {
				SATFinal.forEach(sat => {
					if (!option.children.some((c: any) => c.route === sat.route)) {
						option.children.push({ ...sat, isVisible: false })
					}
				})
				option = { ...filterSAT(option) }
			} else if (option.title === 'Locations') {
				if (!isEamPlantManagerRole(usersList.currentUserPermissions)) {
					// As per the existing implementation,, For EAM user role we need to show the Locations 
					option.children.forEach((child: any) => {
						child.isVisible = true
					})
					option.isSectionVisible = true
				} else {
					option.children.forEach((child: any) => {
						child.isVisible = false
					})
					option.isSectionVisible = false
				}
			} else if (option.title === 'Management') {
				// As per the existing implementation, only ARCH Admin and ARCH Developer Role can access Management section.
			} else if (option.title === 'Alerts') {
				// option.children.forEach((child: any) => { child.isVisible = false });
				// option.isSectionVisible = !isEamPlantManagerRole(usersList.currentUserPermissions);
				if (
					isEamPlantManagerRole(usersList.currentUserPermissions) ||
					checkUserHasOnlyDowntimeUsecaseAccess() ||
					checkUserHasCIPUsecaseAccess()
				) {
					/** As per the existing implementation, if the user role has only `EAM related role` or `Downtime Requests related role` or `CIP use case related role`, 
					 * then we should hide the Alerts (Tickets) section */
					option.children.forEach((child: any) => {
						child.isVisible = false
					})
					option.isSectionVisible = false
				} else {
					option.children.forEach((child: any) => {
						child.isVisible = true
					})
					option.isSectionVisible = true
				}
			}
			return option
		})
		const updatedOpts = final.map((option: Directory, pIndex: number) => {
			option.isOpen = indexData.parentIndex === pIndex ? true : false
			option.children.map((child, cIndex) => child.isSelected = (indexData.parentIndex === pIndex) ? (indexData.childIndex === cIndex ? true : false) : false)
			return option
		})
		setOptions(updatedOpts)

	}

	/**
	 * @function filteringUsecase will filter which use case we need to show based on the current logged in user. 
	 * The access of the use case will be present in the `usecaseList.usecases` state variable.
	 * Based on the `usecaseList.usecases` array we are controlling the boolean isVisible either true or false. 
	 *  
	 * @param usecaseObj from the MenuDirectory use case object
	 * @returns processed use case object based on logged in user role. 
	 */
	const filteringUsecase = (usecaseObj: any) => {
		let accessibleUseCases: any
		const routeItems = [
			'lgv-nav',
			'lgv-hsh',
			'lgv-pls',
			'lgv-ish',
			'lgv-bsh',
			'downtime-request'
		]
		if (
			usersList.currentUserPermissions.includes('ARCH_DEVELOPER') || 
			usersList.currentUserPermissions.includes('ARCH_STAKEHOLDER')
		) {
			accessibleUseCases = usecaseList.usecases
		} else {
			accessibleUseCases = usecaseList.usecases?.filter((usecase: any) => {
				if (levelsList.uniqueUsecases.includes(usecase?._id) || routeItems.includes(usecase?.routes_use_code) ||
					usecase?.routes_use_code === 'removed-lgv') {
					return usecase;
				}
			})
		}
		const useCase = {
			...usecaseObj,
			children: usecaseObj.children.map((child: any) => {
				const usecase = accessibleUseCases.find(
					(usecase: any) => usecase.level_use_code === child.level_use_code
				)
				if (usecase) {
					return { ...child, isVisible: true }
				}
				return child
			})
		}
		useCase.isSectionVisible =
			useCase.children.findIndex((item: any) => item.isVisible === true) > -1
		return useCase
	}

	/**
	 * @function filterEAM will check whether we need to show EAM or not based on the current logged in user role.
	 * 
	 * If user role list has ARCH_USECASE_ALL or ARCH_USECASE_EAM or ARCH_STAKEHOLDER or currentPermissions items then we need to show the EAM.
	 * 
	 * @param eamObj from the MenuDirectory eam object
	 * @returns processed eam object based on logged in user role
	 */
	const filterEAM = (eamObj: any) => {
		let eam = { ...eamObj }
		if (
			usersList.currentUserPermissions.some(
				(item: any) =>
				  item?.includes("ARCH_USECASE_ALL") ||
				  item?.includes("ARCH_USECASE_EAM") ||
				  item?.includes("ARCH_STAKEHOLDER")
				)
		) {
			eam = {
				...eamObj,
				isSectionVisible: true,
				children: eamObj.children.map((child: any) => {
					return { ...child, isVisible: true }
				})
			}
		}
		return eam
	}

	/**
	 * @function filterSAT will control SAT menu based on logged in user role. 
	 * As per the existing implementation, Based on multiple role and role based combination we need to control the SAT menu items. 
	 * Kindly find details in the inline comments.
	 * 
	 * @param satObj from the MenuDirectory SAT object and SATFinal
	 * @returns processed sat object based on logged in user role
	 */
	const filterSAT = (satObj: any) => {
		const sat = { ...satObj }

		const checkSATItems = sat.children.filter((ele: any) => {
			return (
				ele.name === 'SAT Summary' ||
				usersList.currentUserPermissions
					.filter((ele: any) => ele.includes('ARCH_PM_'))
					.map((plant: any) => plant.substring(8, plant.length))
					.includes(ele.name.substring(0, ele.name.indexOf('-')))
			)
		})

		/**
		 * @Block Only Plant Manager
		 */
		if (
			usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_PM_')
			) &&
			!isEamPlantManagerRole(usersList.currentUserPermissions) &&
			!usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_USECASE_SAT')
			) &&
			!usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_USECASE_ALL')
			) &&
			checkSATItems.length > 1
		) {
			checkSATItems.forEach((temp: any) => {
				const index = sat.children.findIndex((c: any) => c.route === temp.route)
				if (index > -1) {
					sat.children[index].isVisible = true
				}
			})
		}

		/**
		 * @Block Plant Manager with Stakeholder or Usecase All or Usecase SAT
		 */
		if (
			usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_PM_')
			) &&
			(usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_STAKEHOLDER')
			) ||
				usersList.currentUserPermissions.some((ele: any) =>
					ele.includes('ARCH_USECASE_SAT')
				) ||
				usersList.currentUserPermissions.some((ele: any) =>
					ele.includes('ARCH_USECASE_ALL')
				))
		) {
			checkSATItems.forEach((temp: any) => {
				const index = sat.children.findIndex((c: any) => c.route === temp.route)
				if (index > -1) {
					sat.children[index].isVisible = true
				}
			})
		}

		/**
		 * @Block Not Plant Manager but Stakeholder or Usecase All or Usecase SAT
		 */
		if (
			!usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_PM_')
			) &&
			(usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_STAKEHOLDER')
			) ||
				usersList.currentUserPermissions.some((ele: any) =>
					ele.includes('ARCH_USECASE_SAT')
				) ||
				usersList.currentUserPermissions.some((ele: any) =>
					ele.includes('ARCH_USECASE_ALL')
				))
		) {
			sat.children.forEach((child: any) => {
				child.isVisible = true
			})
		}
		sat.isSectionVisible =
			sat.children.findIndex((item: any) => item.isVisible === true) > -1
		return sat
	}

	/**
	 * Link to MonARCH URL
	 */
	const monARCHClickCTA = () => {
		const monARCH = `${env.baseURL_monarch.substring(0, env.baseURL_monarch.indexOf('/api/'))}`
		window.open(monARCH, '_blank', 'noreferrer')
	}

	/**
	 * @function handleMediaChange to capture the screen width greater than 2000px  (i.e., 2k resolution).
	 * If the resolution is greater than 2k we need to show the side nav open by default.
	 * 
	 * @param event browser width resize event
	 */
	const handleMediaChange = (event: any) => {
		setScreenWidth(event.matches);
	};

	/**
	 * @function selectOptionsInUIBasedOnRouteChange to select the item on UI.
	 */
	const selectOptionsInUIBasedOnRouteChange = () => {
		let parentIndex = -1;
		let childIndex = -1;

		options.forEach((parent: any, pIndex: number) => {
			const cIndex = parent.children.findIndex((c: any) => c.route === location.pathname);
			if(cIndex > -1) {
				parentIndex = pIndex;
				childIndex = cIndex;
			}
		});
		return { parentIndex, childIndex }		
	}

	const setSelectionCTA = () => {
		const indexData = selectOptionsInUIBasedOnRouteChange();
		const updatedOpts = options.map((option: Directory, pIndex: number) => {
			option.isOpen = indexData.parentIndex === pIndex ? true : false
			option.children.map((child, cIndex) => child.isSelected = (indexData.parentIndex === pIndex) ? (indexData.childIndex === cIndex ? true : false) : false)
			return option
		})
		setOptions(updatedOpts)
	}

	/**
	 * @hook will trigger only once post component load to capture the screen width.
	 * 
	 * If the Screen width grater than 2000px or 2k resolution by default we need to show the side nav with Expanded mode.
	 * This function will capture the screen with dynamically and update the @variable screenWidth true or false.
	 */
	React.useEffect(() => {
		const mediaQueryList = window.matchMedia('(min-width: 2000px)');
		setScreenWidth(mediaQueryList.matches);
		mediaQueryList.addEventListener('change', handleMediaChange);
		return () => {
			mediaQueryList.removeEventListener('change', handleMediaChange);
		};
	}, []);

	/**
	 * @hook will trigger on change at the @variable screenWidth.
	 * If true open the side nav, false keep the default side nav view i.e with icons
	 */
	React.useEffect(() => {
		if(screenWidth) {
			handleDrawerOpen();
		} else {
			handleDrawerClose();
		}
	}, [screenWidth]);

	React.useEffect(() => {
		const groupIndex = options.findIndex((i: { isOpen: boolean }) => i.isOpen === true)
		setClickedIndex(groupIndex)
	}, [options])

	React.useEffect(() => {
		options.forEach((o: { isOpen: boolean }, i: number) => {
			o.isOpen = clickedIndex === i
		})
	}, [clickedIndex])

	/**
	 * @hook will trigger on load of the component after the currentPermissions state updated.
	 * 
	 * This is the main hook that decides the menu to display based on the current logged in user role. 
	 * 
	 */
	React.useEffect(() => {
		if (usersList.currentUserPermissions.includes('ARCH_ADMIN')) {
			enableAccessForAdminRole()
		} else if (usersList.currentUserPermissions.includes('ARCH_DEVELOPER')) {
			enableAccessForDeveloperRole()
		} else if (
			usersList.currentUserPermissions.includes('ARCH_STAKEHOLDER') ||
			usersList.currentUserPermissions.includes('ARCH_ASSET_MANAGER') ||
			usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_PM_')
			) ||
			usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_USECASE_')
			) ||
			usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_SCM_')
			)
		) {
			enableAccessForOtherRolesAndRoleCombinations()
		} else if (
			usersList.currentUserPermissions.some((ele: any) =>
				ele.includes('ARCH_SAT_LEAD')
			)
		) {
			enableAccessForSATLEADRole()
		}
	}, [usersList.currentUserPermissions])

	/**
	 * @hook to capture the route changes.
	 */
	React.useEffect(() => {
		if(initialRender.current) {
			initialRender.current = false;
			return;
		} else {
			setSelectionCTA();
		}
	}, [location.pathname]);

	const handleUseCaseLog = (usecase: string) => {
		if (usecase !== "Motors and Pumps PdM") {
		  Axios.post("usecase_activity_logs/insertUseCaseEventLogs", {
			user_id: usersList.currentUser.display_name,
			usecase_type: usecase,
		  });
		}
	  };
	  
	return (
		<>
			<Drawer variant='permanent' open={open} ref={drawerRef}>
				<DrawerHeader></DrawerHeader>
				{open ? (
					<div style={{ height: `calc(100% - 64px)` }}>
						{/* <List disablePadding> */}
						<div style={{ height: `calc(100% - 98px)` }}>
							<CustomScrollbars>
								{options.map((option: any, index: number) => (
									<>
										{option.isSectionVisible && (
											<Accordion
												className={classes.accordion}
												expanded={index === clickedIndex}
												onClick={() =>
													setClickedIndex(clickedIndex === index ? -1 : index)
												}
											>
												<AccordionSummary
													sx={{ height: '70px' }}
													expandIcon={<ExpandMore sx={{ color: 'white' }} />}
													area-controls={option.title}
													id={option.title}
												>
													<ListItemButton
														disableRipple
														sx={{
															minHeight: 48,
															justifyContent: 'initial',
															px: 2.5
														}}
													>
														<ListItemIcon
															sx={{
																minWidth: 0,
																mr: 3,
																justifyContent: 'center',
																color: 'white'
															}}
														>
															{getIcons(option.icon)}
														</ListItemIcon>
														<ListItemText
															primary={option.title}
															sx={{ opacity: 1 }}
														/>
													</ListItemButton>
												</AccordionSummary>
												<AccordionDetails className={classes.accordionDetails}>
													{/* <List disablePadding> */}
													{option.children.map((child: any, cIndex: number) => (
														<>
															{child.isVisible && (
																<ListItem
																	disablePadding
																	className={`${classes.nested}, ${child.isSelected ? classes.itemSelected : ''}`}
																	component={RouterLink}
																	to={child.route}
																	key={child.name}
																	onClick={() => {
																		handleUseCaseLog(child.name);
																		itemClickCTA(index, cIndex)
																	}}
																>
																	<ListItemButton
																		disableRipple
																		sx={{
																			minHeight: 48,
																			justifyContent: 'initial',
																			px: 2.5
																		}}
																	>
																		<ListItemIcon
																			sx={{
																				minWidth: 0,
																				mr: 3,
																				justifyContent: 'center',
																				color: 'white'
																			}}
																		>
																			{getIcons(child.icon)}
																		</ListItemIcon>
																		<ListItemText
																			primary={child.name}
																			sx={{ opacity: 1 }}
																		/>
																	</ListItemButton>
																</ListItem>
															)}
														</>
													))}
													{/* </List> */}
												</AccordionDetails>
											</Accordion>
										)}
									</>
								))}
							</CustomScrollbars>
						</div>
						<List
							sx={{
								position: 'absolute',
								bottom: 0,
								height: '108px',
								width: '100%',
								backgroundColor: theme.palette.background.paper,
								borderTop: `1px solid ${theme.palette.divider}`
							}}
						>
							<ListItem disablePadding>
								{!isEamPlantManagerRole(usersList.currentUserPermissions) && (
									<ListItemButton
										disableRipple
										onClick={() => monARCHClickCTA()}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: 3,
												justifyContent: 'center',
												color: 'white'
											}}
										>
											{<MonarchInitialIcon />}
										</ListItemIcon>
										<ListItemText
											primary={'MonARCH'}
											sx={{ opacity: 1, color: 'white' }}
										/>
									</ListItemButton>
								)}
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton onClick={handleDrawerClose} disableRipple>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: 3,
											justifyContent: 'center',
											color: 'white'
										}}
									>
										<CollapseIcon />
									</ListItemIcon>
									<ListItemText
										primary={'Collapse'}
										sx={{ opacity: 1, color: 'white' }}
									/>
								</ListItemButton>
							</ListItem>
						</List>
						{/* </List> */}
					</div>
				) : (
					<div style={{ height: `calc(100% - 64px)`, cursor: 'pointer' }}>
						<List disablePadding sx={{ height: `calc(100% - 98px)` }}>
							{/* <CustomScrollbars> */}
							{options.map(
								(option: Directory, index: number) =>
									option?.isSectionVisible && (
										<div
											onMouseLeave={onHoverEnds}
											onMouseOver={(e: any) =>
												onHoverStarts(e, index)
											}
											key={option.title}
										>
											<ListItem
												key={option.title}
												disablePadding
												className={`${classes.iconSection} ${option.isOpen ? classes.backgroundColorDefault : ''}`}
											>
												<ListItemButton className={classes.iconSectionButtons}>
													<ListItemIcon
														sx={{
															minWidth: 0,
															mr: 'auto',
															justifyContent: 'center',
															color: 'white'
														}}
														area-owns={option.title}
														area-haspopup='true'
														onBlur={onHoverEnds}
													>
														{getIcons(option.icon)}
													</ListItemIcon>
												</ListItemButton>
											</ListItem>

											{Boolean(anchorElement) && hoveredIndex === index && (
												<Popper
													id={option.title}
													anchorEl={anchorElement}
													open={Boolean(anchorElement)}
													placement='right-start'
													className={classes.popperCard}
												>
													<div className={classes.popperCardInnerStyle}>
														<div className={classes.menuTitle}>
															{option.title}
														</div>
														{option.children.map((child: any, cIndex: any) => (
															<div
																key={child.name}
																className={`${classes.popperItem} ${child.isSelected ? classes.itemSelected : ''}`}
															>
																{child.isVisible && (
																	<MenuItem
																		className={classes.menuItem}
																		onClick={() => {
																			handleUseCaseLog(child.name);
																			onHoverEnds()
																			itemClickCTA(index, cIndex)
																		}}
																		component={RouterLink}
																		to={child.route}
																		key={child.name}
																	>
																		{getIcons(child.icon)}
																		<Typography
																			sx={{
																				opacity: 1,
																				fontSize: '15px',
																				paddingLeft: '20px'
																			}}
																		>
																			{child.name}
																		</Typography>
																	</MenuItem>
																)}
															</div>
														))}
													</div>
												</Popper>
											)}
										</div>
									)
							)}
							{/* </CustomScrollbars> */}
						</List>
						<List
							sx={{
								position: 'absolute',
								bottom: 0,
								height: '108px',
								width: '100%',
								backgroundColor: theme.palette.background.paper,
								borderTop: `1px solid ${theme.palette.divider}`
							}}
						>
							<ListItem disablePadding>
								{!isEamPlantManagerRole(usersList.currentUserPermissions) && (
									<ListItemButton>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: 'auto',
												justifyContent: 'center',
												color: 'white'
											}}
											onClick={() => monARCHClickCTA()}
										>
											<Tooltip title='MonARCH' placement='right'>
												<MonarchInitialIcon />
											</Tooltip>
										</ListItemIcon>
									</ListItemButton>
								)}
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton onClick={handleDrawerOpen}>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: 'auto',
											justifyContent: 'center',
											color: 'white'
										}}
									>
										<Tooltip title='Expand' placement='right'>
											<ExpandIcon />
										</Tooltip>
									</ListItemIcon>
								</ListItemButton>
							</ListItem>
						</List>
					</div>
				)}
			</Drawer>
		</>
	)
}

export default SideNav


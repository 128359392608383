import { useState, useEffect} from 'react';
import {useCubeQuery} from '@cubejs-client/react';
import {ProgressResponse, Query,ResultSet } from '@cubejs-client/core';
import Axios from '../AxiosConfig/axiosConfigSAT';
import { useSelector } from 'react-redux';

/**
* This hooks makes a Cube API call and tracks the call duration along with the success/error response.
*
* @param query - The query to be executed.
* @returns A Promise with the result of the query, including loading, error,progress and refetch function.
*/
interface TrackedCubeQueryResult {
    resultSet: ResultSet | null;
    error: Error | null;
    isLoading: boolean;
    progress: ProgressResponse;
    refetch: () => Promise<void>
}
const useTrackedCubeQuery = (query: Query, options ? : any): TrackedCubeQueryResult => {
    const seedsList = useSelector((state: any) => state.seedsList)
    const [startTime, setStartTime] = useState < number | null > (null)
    const {
        resultSet,
        error,
        isLoading,
        progress,
        refetch
    } = useCubeQuery(query, options)

    // Logger Enabled Functionality
    const loggerSeed = seedsList.seeds.find(
      (ele: any) => ele.seed_key_name === 'Cube Logger'
    )?.seed_data_value

    const isMetricEnabled = loggerSeed && loggerSeed.find((val:any) => val["key"] === "cubeMetric")?.value === 'true'
    const isEventEnabled = loggerSeed && loggerSeed.find((val:any) => val["key"] === "cubeEvent")?.value === 'true'

    useEffect(() => {
        if (isLoading) {
            setStartTime(Date.now())
        }
    }, [isLoading])

    useEffect(() => {
        async function fetchData() {
          if (!isLoading && (resultSet || error)) {
            const endTime = Date.now();
            const duration = startTime ? endTime - startTime : 0;
            // Success Metric Callback
            if(isMetricEnabled){
              const metricPayload = {
                type: "Metric",
                payload: {
                  name: "useCubeQuery Response Time",
                  duration,
                },
              };
              await Axios.post('azureinsightslogger', metricPayload);
            }
            if (resultSet) {
              // Success Event Callback
              if(isEventEnabled){
                const eventPayload = {
                  type: "Event",
                  payload: {
                    name: "useCubeQuery Success",
                    url: window.location.href,
                    query,
                    resultSet,
                  },
                };
                await Axios.post('azureinsightslogger', eventPayload);
              }
            }
            if (error) {
              // Error Event Callback
              if(isEventEnabled){
                const eventPayload = {
                  type: "Event",
                  payload: {
                    name: "useCubeQuery Error",
                    url: window.location.href,
                    query,
                    error,
                  },
                };
                await Axios.post('azureinsightslogger', eventPayload);
              }
            }
          }
        }
        fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [resultSet, error,startTime, isLoading]); 
    
    return {
        resultSet,
        error,
        isLoading,
        progress,
        refetch
    }
}

export default useTrackedCubeQuery;

